import React, { Component } from "react";

export class SellToWebAdminPageComponent extends Component {
    render() {
        return <div className="admin-page">
            <div className={"d-flex flex-column admin-fill-content"}>
                {this.props.children}
            </div>
        </div>;
    }
}
