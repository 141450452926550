import React from "react";

import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";

export class EVOXImagesEVOXVehiclesQueryComponent extends React.Component {

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        try {
            newState.showSpinner = props.isLoadingData;
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "getDerivedStateFromProps");
        }

        return newState;
    }

    viewData = [
        { key: "Unmapped", value: "Unmapped Active" },
        { key: "Mapped Inactive", value: "Mapped Inactive" },
        { key: "Mapped", value: "Mapped" },
        { key: "Active", value: "Active" },
        { key: "Hidden", value: "Hidden" },
        { key: "Inactive", value: "Inactive" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            showSpinner: false,
            vifNumber: null,
            selectedView: null
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);

        //console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        //console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            // console.devLog("EVOXImagesEVOXVehiclesQueryComponent - componentDidMount");
            const params = new URLSearchParams(window.location.search);
            const newParams = new URLSearchParams();
            for (const [name, value] of params) {
                newParams.append(name.toLowerCase(), value);
            }
            let vifNumberParam;
            if (newParams) {
                vifNumberParam = newParams.get("vifnumber");
            }

            this.setState({
                selectedView: this.viewData[0],
                vifNumber: vifNumberParam
            }, this.onGetReportClick);
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "componentDidMount");
        }
    }

    onViewClick(id, view) {
        try {
            this.setState({ selectedView: view });

        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "onViewClick");
        }
    }

    onGetReportClick(event) {
        try {
            this.props.onGetReportClick(this.state.vifNumber, this.state.selectedView);
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "onGetReportClick");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "onTextChange");
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesQueryComponent", "onKeyDown");
        }
    }

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    <SellToWebAdminDropdownComponent label="Mapped" data={this.viewData} itemKey="value" onItemClick={this.onViewClick} value={this.state.selectedView !== null ? this.state.selectedView.value : "Select View"} />
                    <SellToWebAdminInputComponent id="vifNumber" label="VIF" value={this.state.vifNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <div className="col-auto admin-query-btn">
                        <button disabled={this.state.showSpinner} className="btn btn-primary" onClick={this.onGetReportClick}>
                            Get Report<i className="fas fa-redo-alt admin-icon" />
                        </button>
                    </div>
                    <div style={{ "height": "33px", "width": "auto" }}>
                        <SellToWebSpinnerComponent showSpinner={this.state.showSpinner} />
                    </div>
                </div>
            </div>
        </div>;
    }
}
