import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebRadioButtonComponent } from "../common/selltowebradiobutton/SellToWebRadioButtonComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleConditionMMRComponent extends Component {
    accidentData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];
    exteriorData = [
        { key: "Like New", value: "Like New" },
        { key: "Very Good", value: "Very Good" },
        { key: "Acceptable", value: "Acceptable" },
        { key: "Major Issues", value: "Major Issues" }
    ];
    interiorData = [
        { key: "Like New", value: "Like New" },
        { key: "Very Good", value: "Very Good" },
        { key: "Acceptable", value: "Acceptable" },
        { key: "Major Issues", value: "Major Issues" }
    ];
    mechanicalData = [
        { key: "Like New", value: "Like New" },
        { key: "Very Good", value: "Very Good" },
        { key: "Acceptable", value: "Acceptable" },
        { key: "Major Issues", value: "Major Issues" }
    ];
    tireData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];
    windshieldData = [
        { key: "Yes", value: "Yes" },
        { key: "No", value: "No" }
    ];

    constructor(props) {
        super(props);

        this.state = {
            vehicleDetailsData: null,
            selectedVCAccidents: "",
            selectedVCExterior: "",
            selectedVCInterior: "",
            selectedVCMechanical: "",
            selectedVCTire: "",
            selectedVCWindshield: "",
            isYourDetailsClicked: false,
            showSpinner: false,
            redirectTo: ""
        }

        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onRadioButtonListChange = this.onRadioButtonListChange.bind(this);
        this.onYourDetailsClick = this.onYourDetailsClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    pageTitle = "Vehicle Condition";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        try {
            //this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-condition", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, trim: vehicleDetailsData?.selectedTrim?.description?.trim, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (!vehicleDetailsData) {
                CommonService.clientAzureStorageLog("VehicleConditionMMRComponent", "componentDidMount", null, "Redirect to unique-vehicle", null, null, this.props.applicationInsights);
                this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
            }
            else {
                window.scrollTo(0, 0);
                //Get Local Storage Data
                let trim = vehicleDetailsData?.selectedTrim?.description?.trim;
                let lsAccident = CommonService.getLocalStorage("VehicleCondition" + trim + "Accident");
                let lsExterior = CommonService.getLocalStorage("VehicleCondition" + trim + "Exterior");
                let lsInterior = CommonService.getLocalStorage("VehicleCondition" + trim + "Interior");
                let lsMechanical = CommonService.getLocalStorage("VehicleCondition" + trim + "Mechanical");
                let lsTire = CommonService.getLocalStorage("VehicleCondition" + trim + "Tire");
                let lsWindshield = CommonService.getLocalStorage("VehicleCondition" + trim + "Windshield");

                this.setState({
                    selectedVCAccidents: lsAccident,
                    selectedVCExterior: lsExterior,
                    selectedVCInterior: lsInterior,
                    selectedVCMechanical: lsMechanical,
                    selectedVCTire: lsTire,
                    selectedVCWindshield: lsWindshield,
                    vehicleDetailsData: vehicleDetailsData
                });
            }
        }
        catch (error) {
            console.logError(error, "VehicleConditionMMRComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.description?.trim + " " + vehicleDetailsData?.vin);
        }
    }

    onRadioButtonListChange(id, item) {
        try {
            this.setState({ [id]: item.value });
        }
        catch (error) {
            console.logError(error, "VehicleConditionMMRComponent", "onRadioButtonListChange", id);
        }
    }

    onYourDetailsClick() {
        try {
            let inputsValid = this.state.selectedVCAccidents && this.state.selectedVCExterior && this.state.selectedVCInterior && this.state.selectedVCMechanical && this.state.selectedVCTire && this.state.selectedVCWindshield;
            if (inputsValid) {
                let vehicleDetailsData = this.state.vehicleDetailsData;
                let minimumSelectedCondition = CommonService.getMinimumSelectedCondition(this.state.selectedVCExterior, this.state.selectedVCInterior, this.state.selectedVCMechanical);
                let vehicleConditionData = {
                    accidentResponse: this.state.selectedVCAccidents,
                    exterior: this.state.selectedVCExterior,
                    interior: this.state.selectedVCInterior,
                    mechanical: this.state.selectedVCMechanical,
                    tire: this.state.selectedVCTire,
                    windshield: this.state.selectedVCWindshield,
                    minimumSelectedCondition: minimumSelectedCondition
                };
                vehicleDetailsData["vehicleConditionData"] = vehicleConditionData;
                CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);
                let offerSession = CommonService.getSessionStorage("offerSession");
                offerSession.vehicleHistory.accidentResponse = vehicleConditionData.accidentResponse;
                offerSession.vehicleCondition = {};
                offerSession.vehicleCondition.exterior = vehicleConditionData.exterior;
                offerSession.vehicleCondition.interior = vehicleConditionData.interior;
                offerSession.vehicleCondition.mechanical = vehicleConditionData.mechanical;
                offerSession.vehicleCondition.tire = vehicleConditionData.tire;
                offerSession.vehicleCondition.windshield = vehicleConditionData.windshield;
                CommonService.setSessionStorage("offerSession", offerSession);
                //Set Local Storage
                let trim = vehicleDetailsData.selectedTrim.description.trim;
                CommonService.setLocalStorage("VehicleCondition" + trim + "Accident", this.state.selectedVCAccidents);
                CommonService.setLocalStorage("VehicleCondition" + trim + "Exterior", this.state.selectedVCExterior);
                CommonService.setLocalStorage("VehicleCondition" + trim + "Interior", this.state.selectedVCInterior);
                CommonService.setLocalStorage("VehicleCondition" + trim + "Mechanical", this.state.selectedVCMechanical);
                CommonService.setLocalStorage("VehicleCondition" + trim + "Tire", this.state.selectedVCTire);
                CommonService.setLocalStorage("VehicleCondition" + trim + "Windshield", this.state.selectedVCWindshield);
                this.setState({
                    redirectTo: "/your-details"
                });
            }
            else {
                this.scrollToTop();
            }
            this.setState({
                isYourDetailsClicked: true
            });
        }
        catch (error) {
            console.logError(error, "VehicleConditionMMRComponent", "onYourDetailsClick");
        }
    }

    onBackButtonClick() {
        try {
            window.history.go(-1);
        }
        catch (error) {
            console.logError(error, "VehicleConditionMMRComponent", "onBackButtonClick");
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.onYourDetailsClick();
        }
    }

    scrollToTop() {
        let scrollToElement = null;
        if (!this.state.selectedVCAccidents) {
            scrollToElement = this.vcAccidentsContainer;
        }
        else if (!this.state.selectedVCExterior) {
            scrollToElement = this.vcExteriorContainer;
        }
        else if (!this.state.selectedVCInterior) {
            scrollToElement = this.vcInteriorContainer;
        }
        else if (!this.state.selectedVCMechanical) {
            scrollToElement = this.vcMechanicalContainer;
        }
        else if (!this.state.selectedVCTire) {
            scrollToElement = this.vcTireContainer;
        }
        else {
            scrollToElement = this.vcWindshieldContainer;
        }

        if (scrollToElement) {
            let scrollElement = ReactDOM.findDOMNode(scrollToElement)
            let offsetPosition = scrollElement.getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Condition", "Default", "Body Html")?.Value ?? "";

        return <Fragment>
            <SellToWebTopPanelComponent imageUrl={this.state.vehicleDetailsData?.imageUrl} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.description.trim : "--"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehiclecondition-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="ac-vehiclecondition-form" onKeyDown={(event) => this.onKeyDown(event)} tabIndex={-1}>
                        <div ref={refElement => this.vcAccidentsContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCAccidents ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>
                                Accidents
                                {
                                    this.state.isYourDetailsClicked ? !this.state.selectedVCAccidents
                                        ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span>
                                        : "" : ""
                                }
                            </h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Has your vehicle been in any accidents?</p>
                            <SellToWebRadioButtonComponent id="selectedVCAccidents" name="rblAccident" data={this.accidentData} itemKey="value" value={this.state.selectedVCAccidents ? this.state.selectedVCAccidents : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                        </div>
                        <hr className="consumer-vehiclecondition-seperator" />

                        <div ref={refElement => this.vcExteriorContainer = refElement} >
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCExterior ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Exterior Condition  {this.state.isYourDetailsClicked ? !this.state.selectedVCExterior ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of bumpers, fenders, doors, roof, hood and trunk.</p>
                            <SellToWebRadioButtonComponent id="selectedVCExterior" name="rblExterior" data={this.exteriorData} itemKey="value" value={this.state.selectedVCExterior ? this.state.selectedVCExterior : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div >
                        <div ref={refElement => this.vcInteriorContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCInterior ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Interior Condition  {this.state.isYourDetailsClicked ? !this.state.selectedVCInterior ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of seats, carpet, headliner, dash, and equipment.</p>
                            <SellToWebRadioButtonComponent id="selectedVCInterior" name="rblInterior" data={this.interiorData} itemKey="value" value={this.state.selectedVCInterior ? this.state.selectedVCInterior : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcMechanicalContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCMechanical ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Mechanical Condition  {this.state.isYourDetailsClicked ? !this.state.selectedVCMechanical ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Condition of engine, transmission, suspension, and brakes.</p>
                            <SellToWebRadioButtonComponent id="selectedVCMechanical" name="rblMechanical" data={this.mechanicalData} itemKey="value" value={this.state.selectedVCMechanical ? this.state.selectedVCMechanical : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcTireContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCTire ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Tire Condition {this.state.isYourDetailsClicked ? !this.state.selectedVCTire ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Do your tires have more than 50% tread remaining? Are they all matching?</p>
                            <SellToWebRadioButtonComponent id="selectedVCTire" name="rblTire" data={this.tireData} itemKey="value" value={this.state.selectedVCTire ? this.state.selectedVCTire : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div ref={refElement => this.vcWindshieldContainer = refElement}>
                            <h4 className={"fw-bold " + (this.state.isYourDetailsClicked ? !this.state.selectedVCWindshield ? "consumer-vehiclecondition-border-error" : "" : "")} style={{ fontSize: "15px" }}>Windshield Condition {this.state.isYourDetailsClicked ? !this.state.selectedVCWindshield ? <span className="fas fa-arrow-left ac-site-error consumer-vehiclecondition-error"></span> : "" : ""}</h4>
                            <p style={{ fontSize: "15px", fontWeight: "500" }}>Is your windshield free of cracks and major chips?</p>
                            <SellToWebRadioButtonComponent id="selectedVCWindshield" name="rblWindshield" data={this.windshieldData} itemKey="value" value={this.state.selectedVCWindshield ? this.state.selectedVCWindshield : ""} onChange={this.onRadioButtonListChange}></SellToWebRadioButtonComponent>
                            <hr className="consumer-vehiclecondition-seperator" />
                        </div>
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onYourDetailsClick} showSpinner={this.state.showSpinner} value="Your Details" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={this.state.vehicleDetailsData?.vin} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} valuationMethod={"MMR"}
                        imageUrl={this.state.vehicleDetailsData?.imageUrl} differentColorShown={this.state.vehicleDetailsData?.differentColorShown} odometer={CommonService.formatMoney(this.state.vehicleDetailsData?.odometer)}
                        colorBox={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedVehicleOEMColor.colorBox) : null}
                        colorName={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.title ? this.state.vehicleDetailsData?.selectedVehicleOEMColor.title : this.state.vehicleDetailsData?.selectedVehicleOEMColor?.optionName}
                        trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.description.trim : "--"}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.props.applicationInsights}
                    />
                </aside>
            </section>
        </Fragment>
    }
}