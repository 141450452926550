import moment from "moment";
import tz from "moment-timezone";

export class CommonService {

    static tablePageSize = 50;

    static setSessionStorage(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    static getSessionStorage(key) {
        return JSON.parse(sessionStorage.getItem(key));
    }

    static setLocalStorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getLocalStorage(key) {
        return JSON.parse(localStorage.getItem(key));
    }

    static removeLocalStorage(key) {
        localStorage.removeItem(key);
    }

    static getSiteAttributeValue(siteAttributes, area, formFactor, name) {
        let attribute = null;
        // if(window.innerWidth <= 768){
        //     console.log("This is a mobile site");
        // }
        // else{
        //     console.log("This is a desktop site");
        // }
        if (siteAttributes) {
            let key = area + "~" + formFactor + "~" + name;
            attribute = siteAttributes[key];
            if ((!attribute || !attribute.Value) && formFactor !== "Default") {
                key = area + "~Default~" + name;
                attribute = siteAttributes[key];
            }
        }

        return attribute;
    }

    static clearLocalStorage(vin, vehicleId) {
        this.removeLocalStorage("HomeVINorLP");
        this.removeLocalStorage("HomeLicenseState");
        this.removeLocalStorage("Home" + vin + "SelectedTrimId");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "SelectedEngineId");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "SelectedTransmissionId");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "SelectedDriveTrainId");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "selectedVehicleOEMColorCode");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "Odometer");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "ImageUrl");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "DifferentColorShown");
        this.removeLocalStorage("Home" + vin + "SelectedTrim");
        this.removeLocalStorage("VehicleDetails" + vehicleId + "SelectedColor");

        this.removeLocalStorage("VehicleEquipment" + vehicleId + "SelectedOptions");

        this.removeLocalStorage("VehicleCondition" + vehicleId + "Accident");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Paint");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Wheels");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Window");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Upholstery");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Interior");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Mechanical");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Keys");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Exterior");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Tire");
        this.removeLocalStorage("VehicleCondition" + vehicleId + "Windshield");

        this.removeLocalStorage("YourDetails" + vehicleId + "FirstName");
        this.removeLocalStorage("YourDetails" + vehicleId + "LastName");
        this.removeLocalStorage("YourDetails" + vehicleId + "Email");
        this.removeLocalStorage("YourDetails" + vehicleId + "Phone");
        this.removeLocalStorage("YourDetails" + vehicleId + "Zip");
    }

    static clearSessionStorage() {
        sessionStorage.clear();
    }

    static clientAzureStorageLog(component, part2, part3, part4, part5, body, applicationInsights) {
        let logBody = {
            logType: "info",
            component: component,
            part2: part2 ?? "",
            part3: part3 ?? "",
            part4: part4 ?? "",
            part5: part5 ?? "",
            body: body ?? ""
        };
        applicationInsights?.trackTrace({ message: "/CommonService/clientAzureStorageLog", properties: { ...logBody } });
        return fetch("/Api/Log", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: JSON.stringify(logBody),
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response; } else { throw response; } });
    }


    static clientAzureStorageError(component, part2, part3, part4, part5, err) {
        let inputData = JSON.stringify({
            logType: "error",
            component: component,
            part2: part2,
            part3: part3 ?? "",
            part4: part4 ?? "",
            part5: part5 ?? "",
            body: err ?? ""
        });
        return fetch("/Api/Log", {
            method: "POST",
            headers: {
                "Content-Type": "application/json; charset=utf-8;"
            },
            body: inputData,
            credentials: "same-origin"
        })
            .then(response => { if (response.ok) { return response; } else { throw response; } });
    }

    static formatBoolString(value) {
        return value ? "Yes" : "No";
    }

    static currencyFormatter = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    static currencyFormatter_1 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    });

    static currencyFormatter_2 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
    });

    static formatCurrency(value) {
        return CommonService.currencyFormatter.format(value ? value : 0);
    }

    static formatCurrency_1(value) {
        return CommonService.currencyFormatter_1.format(value ? value : 0);
    }

    static formatCurrency_2(value) {
        return CommonService.currencyFormatter_2.format(value ? value : 0);
    }

    static formatCurrency_2_simple(value) {
        return CommonService.currencyFormatter.format(value ? value : 0);
    }

    static formatSignedCurrency(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.currencyFormatter.format(value ? value : 0);
    }

    static formatAccountingAmount(value) {
        return CommonService.currencyFormatter_2.format(value ? value : 0).replace("-", "");
    }

    static formatAccountingAmount_simple(value) {
        return CommonService.currencyFormatter.format(value ? value : 0).replace("-", "");
    }

    static formatNumber(value) {
        return CommonService.currencyFormatter.format(value ? value : 0).replace("$", "");
    }

    static formatNumber_1(value) {
        return CommonService.currencyFormatter_1.format(value ? value : 0).replace("$", "");
    }

    static formatNumber_2(value) {
        return CommonService.currencyFormatter_2.format(value ? value : 0).replace("$", "");
    }

    static formatPercentage(value) {
        return CommonService.percentageFormatter.format(value ? value : 0);
    }

    static percentageFormatter_1 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "percent",
        minimumFractionDigits: 1
    });
    static formatPercentage_1(value) {
        return CommonService.percentageFormatter_1.format(value ? value : 0);
    }

    static percentageFormatter_2 = new Intl.NumberFormat("en-US", { //navigator.language || navigator.userLanguage
        style: "percent",
        minimumFractionDigits: 2
    });
    static formatPercentage_2(value) {
        return CommonService.percentageFormatter_2.format(value ? value : 0);
    }

    static formatSignedPercentage(value) {
        let sign = "";
        if (value > 0) {
            sign = "+";
        }

        return sign + CommonService.percentageFormatter.format(value ? value : 0);
    }

    static formatMonthYear(value) {
        return moment(value).format("MMMM YYYY");
    }

    static formatYear(value) {
        return moment(value).format("YYYY");
    }

    static formatMoney(value) {
        if (value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    static formatDate(value) {
        return moment(value).format("MM/DD/YYYY");
    }

    static formatDate_mmddyy(value) {
        return moment(value).format("MM/DD/YYYY");
    }

    static formatDate_mmddyyyy(value) {
        return moment(this.formatDateToISOString(value)).format("MM/DD/YYYY");
    }

    static formatDateTime(value) {
        return moment(value).format("MM/DD/YYYY hh:mm A");
    }

    static formatDateTime_1(value) {
        return moment(value).format("MM/DD/YY hh:mm A");
    }

    static formatDateTime_2(value) {
        return moment(value).format("MM/DD/YYYY HH:mm");
    }

    static formatDateTimeZone(utcDateTime) {
        let localDateTime = moment(utcDateTime).local();
        return localDateTime.format("MM/DD/YYYY hh:mm A ") + tz.tz.zone(tz.tz.guess()).abbr(localDateTime);
    }

    static formatMonth(value) {
        return moment(value).format("MMM");
    }

    static formatTime(value) {
    }

    static ordinalColumnName = "sellToWeb_column_ordinal";
    static addOrdinalColumn(rowTableData) {
        if (rowTableData && rowTableData.length) {
            rowTableData.forEach((rowData, index) => {
                rowData[CommonService.ordinalColumnName] = index + 1;
            });
        }

        return rowTableData;
    }

    static sortJsonData(tableData, sortColumn, sortOrder, dataType) {
        if (tableData && tableData.length > 0 && sortColumn && sortOrder) {
            // Sort data here
            tableData.sort((row1, row2) => {
                let row1Data = row1;
                let row2Data = row2;
                sortColumn.split(".").forEach((columnName) => {
                    row1Data = row1Data[columnName];
                    row2Data = row2Data[columnName];
                });

                if (row1Data === row2Data) {
                    if (sortOrder === "Asc") {
                        return row1[CommonService.ordinalColumnName] > row2[CommonService.ordinalColumnName] ? 1 : -1;
                    } else {
                        return row1[CommonService.ordinalColumnName] < row2[CommonService.ordinalColumnName] ? 1 : -1;
                    }
                } else if (row1Data === null || row2Data === null) {
                    if (sortOrder === "Asc") {
                        return row2Data === null ? 1 : -1;
                    } else {
                        return row1Data === null ? 1 : -1;
                    }
                }
                else {
                    if (row1Data !== null && row2Data !== null && dataType === null) {
                        if (sortOrder === "Asc") {
                            return row1Data.toLowerCase() > row2Data.toLowerCase() ? 1 : -1;
                        } else {
                            return row1Data.toLowerCase() < row2Data.toLowerCase() ? 1 : -1;
                        }
                    } else {
                        if (sortOrder === "Asc") {
                            return row1Data > row2Data ? 1 : -1;
                        } else {
                            return row1Data < row2Data ? 1 : -1;
                        }
                    }
                }
            });

            return tableData;
        }

        return tableData;
    }

    static getMinimumSelectedCondition(paint, wheels, window, upholstery, interior, mechanical) {
        let paintScore = paint === "Like New" ? 4 : paint === "Very Good" ? 3 : paint === "Acceptable" ? 2 : paint === "Major Issues" ? 1 : 0;
        let wheelsScore = wheels === "Like New" ? 4 : wheels === "Very Good" ? 3 : wheels === "Acceptable" ? 2 : wheels === "Major Issues" ? 1 : 0;
        let windowScore = window === "Like New" ? 4 : window === "Very Good" ? 3 : window === "Acceptable" ? 2 : window === "Major Issues" ? 1 : 0;
        let upholsteryScore = upholstery === "Like New" ? 4 : upholstery === "Very Good" ? 3 : upholstery === "Acceptable" ? 2 : upholstery === "Major Issues" ? 1 : 0;
        let interiorScore = interior === "Like New" ? 4 : interior === "Very Good" ? 3 : interior === "Acceptable" ? 2 : interior === "Major Issues" ? 1 : 0;
        let mechanicalScore = mechanical === "Like New" ? 4 : mechanical === "Very Good" ? 3 : mechanical === "Acceptable" ? 2 : mechanical === "Major Issues" ? 1 : 0;

        let minScore = Math.min(paintScore, wheelsScore, windowScore, upholsteryScore, interiorScore, mechanicalScore);
        let result;
        switch (minScore) {
            case 4:
                result = "Like New";
                break;
            case 3:
                result = "Very Good";
                break;
            case 2:
                result = "Acceptable";
                break;
            case 1:
                result = "Major Issues";
                break;
            default:
                result = "";
                break;
        }

        return result;
    }

    static getComponentConstructorData(componentValues) {
        let componentValuesArray = [];
        if (componentValues !== null) {
            Object.keys(componentValues).map((componentValue) => componentValuesArray.push(componentValue));
        }

        return componentValuesArray.join(", ");
    }

    static getPreviousYearsByDate(noOfyears) {
        let startDate = moment().subtract(noOfyears, "years");
        let dateCounter = moment().startOf("year").add(1, "years");
        let years = [];
        while (dateCounter > startDate) {
            years.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "year");
        }

        return years;
    }

    static formatPhoneNumber(phoneNumberString, addBraces = null) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        if (cleaned.length > 10) {
            let prefix = cleaned.substr(0, cleaned.length - 10);
            cleaned = cleaned.slice(-10);
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return this.addOrRemoveBracesWithPrefix(match, prefix, addBraces);
            }
        }
        else {
            let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return this.addOrRemoveBraces(match, addBraces);
            }
        }
        return null;
    }

    static addOrRemoveBracesWithPrefix(match, prefix, addBraces) {
        if (addBraces != null) {
            return '+' + prefix + match[1] + '-' + match[2] + '-' + match[3];
        }
        else {
            return '+' + prefix + ' (' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }

    static addOrRemoveBraces(match, addBraces) {
        if (addBraces != null) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        else {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
    }

    static getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    static formatServerDateTime(value) {
        return moment(this.formatDateToISOString(value)).format("MM-DD-YYYY");
    }

    static formatDateToISOString(value) {
        if (value && value._isValid) {
            let date = new Date(value);
            return date.toISOString();
        }

        return value;
    }

    static filterJsonData(tableData, filterColumn, filterText, dataType, filterOption) {
        var filterObject;
        if (dataType === "date") {
            filterObject = new Date(filterText);
        }
        var filteredTableData;
        if (!filterText || filterText === "") {
            filteredTableData = tableData;
        } else {
            filteredTableData = tableData.filter((obj) => {
                let found = false;
                let cellData = obj;
                filterColumn.split(".").forEach((columnName) => {
                    cellData = cellData[columnName];
                });

                if (cellData && !found) {
                    switch (dataType) {
                        case "date":
                            let filterData = new Date(cellData);
                            switch (filterOption) {
                                case "exclude":
                                    found = filterObject.getTime() !== filterData.getTime();
                                    break;
                                default:
                                    found = filterObject.getTime() === filterData.getTime();
                                    break;
                            }
                            break;
                        case "number":
                            switch (filterOption) {
                                case "exclude":
                                    found = cellData.toString() !== filterText.toString();
                                    break;
                                default:
                                    found = cellData.toString() === filterText.toString();
                                    break;
                            }
                            break;
                        default:
                            switch (filterOption) {
                                case "exclude":
                                    found = cellData.toString().search(new RegExp(filterText, "i")) === -1;
                                    break;
                                default:
                                    found = cellData.toString().search(new RegExp(filterText, "i")) !== -1;
                                    break;
                            }
                            break;
                    }
                }
                return found;
            });
        }

        return filteredTableData;
    }

    static isDateValid(dateString) {
        return moment(dateString).isValid() ? moment(dateString) : false;
    }

    static createGuid() {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    static checkCSSColorSupports(colorName) {
        return window.CSS && window.CSS.supports('color', colorName) ? colorName : colorName === "Burgundy" ? "Maroon" : "";
    }

    static getYears(fromYear, showNull = false, toYear = null) {
        let startDate = moment(fromYear, "MM/DD/YYYY");
        let dateCounter = moment().startOf("year");
        if (toYear) {
            dateCounter = moment(toYear, "MM/DD/YYYY").startOf("year");
        }
        let years = [];
        if (showNull) {
            years = [null];
        }
        while (dateCounter > startDate) {
            years.push(moment(dateCounter));
            dateCounter = dateCounter.subtract(1, "year");
        }
        return years;
    }
}