import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { CommonService } from "../../js_modules/CommonService";

export class SellToWebAdminSideBarComponent extends Component {
    static PathNameCookie = "SellToWebSideBarPathNameCookie";
    constructor(props) {
        super(props);
        this.state = {
            pathName: window.location.pathname,
            activeMenuId: "",
            lastActiveMenuId: "menuItem-0",
        };

        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
    }

    createMenu(menuItems) {
        let menu = [];
        if (menuItems) {
            menuItems.forEach((menuItem, index) => {
                menu.push(<li key={"menuItem-" + index} className={"nav-item" + (this.isMenuActive(menuItem) ? " active" : "")}>
                    {
                        menuItem.childMenuItems
                            ? <Fragment>
                                <button key="menu" type="button" className="btn btn-link nav-link collapsed" data-toggle="collapse" data-target=".nav-collapse.in" onClick={(event) => this.onMenuTitleClick(event, "menuItem-" + index)}>
                                    <i className={"admin-sidebar-menu-icon " + menuItem.iconClassName} />
                                    &nbsp;&nbsp;<span>{menuItem.title}</span>
                                </button>
                                <ul key="sub-menu" className={"nav flex-column collapse" + (this.canShowMenu(menuItem.childMenuItems, index) ? " show" : "")}>
                                    {this.createMenu(menuItem.childMenuItems)}
                                </ul>
                            </Fragment>
                            : <Link className="nav-link" to={menuItem.href} onClick={(event) => this.onMenuItemClick(event, menuItem.href)}>
                                {
                                    menuItem.iconClassName
                                        ? <i className={"admin-sidebar-menu-icon " + menuItem.iconClassName} />
                                        : null
                                }
                                <span>&nbsp;&nbsp;{this.props.sideBarState === "Expanded" ? menuItem.title : ""}</span>
                            </Link>
                    }
                </li>
                );
            });
        }
        

        return menu;
    }

    onMenuItemClick(id, link) {
        if (this.state.activeMenuId === id) {
            this.setState({
                activeMenuId: "Collapsed",
                lastActiveMenuId: "Collapsed"
            });
        } else {
            this.setState({
                activeMenuId: id,
                lastActiveMenuId: id,
                pathName: link
            });
        }
    }

    onSettingsClick(id, link) {
        CommonService.setCookie(SellToWebAdminSideBarComponent.PathNameCookie, link, 30);
        this.setState({
            pathName: link
        });
    }

    isMenuActive(menuItem) {
        if (menuItem.href) {
            if (this.state.pathName.toLocaleLowerCase() === menuItem.href.toLocaleLowerCase()) {
                return true;
            }
            else {
                let pathSegment = this.state.pathName.split('/');
                let menuHrefSegment = menuItem.href.split('/');
                if (pathSegment.length === 2) {
                    return pathSegment[1].toLocaleLowerCase() === menuHrefSegment[2].toLocaleLowerCase();
                }

                return pathSegment[2].toLocaleLowerCase() === menuHrefSegment[2].toLocaleLowerCase();
            }
        }

        return false;
    }

    isSettingsActive() {
        let savedPathName = CommonService.getCookie(SellToWebAdminSideBarComponent.PathNameCookie);
        let currentPathName = window.location.pathname;
        if (savedPathName.toLocaleLowerCase() === currentPathName?.toLocaleLowerCase()) {
            return true;
        } else {
            return false;
        }
    }


    render() {
        let isTenant = window.location.pathname.toLocaleLowerCase().startsWith("/tenant");

        return <div className={"admin-sidebar " + (this.props.sideBarState === "Collapsed" ? "admin-sidebar-collapsed" : "")}>
            <div className="admin-sidebar-logo">
                <a href="/">
                    <div className="d-inline">
                        <img src="images/group.svg" alt="Group" />
                        {
                            this.props.sideBarState === "Expanded"
                                ? <h4 className="d-inline app-font-family-bold app-fs-15 admin-header-text">ACQUIRE CARS</h4>
                                : null
                        }
                    </div>
                </a>
            </div>
            {
                this.props.isAuthenticated && (this.props.menuItems.length > 0 || this.props.globalMenuItems.length > 0)
                    ? <Fragment>
                        <ul className="nav flex-column" style={{ paddingBottom: "40px" }}>
                            {
                                isTenant
                                    ? this.createMenu(this.props.menuItems)
                                    : this.createMenu(this.props.globalMenuItems)
                            }
                        </ul>
                        <div className="admin-sidebar-settings">
                            {
                                this.props.sideBarState === "Expanded" && (this.props.isGlobalAdmin || this.props.isTenantAdmin)
                                    ? <Fragment>
                                        {
                                            this.isSettingsActive()
                                                ? <i className="fa fa-cog fa-1" aria-hidden="true" style={{ color: '#218DEF' }}></i>
                                                : <i className="fa fa-cog fa-1" aria-hidden="true"></i>
                                        }
                                        &nbsp;
                                        {
                                            !isTenant && this.props.isGlobalAdmin
                                                ? <Link to="Global/Settings" className={"admin-sidebar-settings-link" + (this.isSettingsActive() ? " active" : "")} onClick={(event) => this.onSettingsClick(event, '/Global/Settings')}>Global Settings</Link>
                                                : null
                                        }
                                        {
                                            isTenant && this.props.isTenantAdmin
                                                ? <Link to="Tenant/Settings" className={"admin-sidebar-settings-link" + (this.isSettingsActive() ? " active" : "")} onClick={(event) => this.onSettingsClick(event, '/Tenant/Settings')}>Settings</Link>
                                                : null
                                        }
                                    </Fragment>
                                    : <i className="fa fa-cog fa-1 admin-sidebar-settings-icon" aria-hidden="true"></i>
                            }
                            <button type="button" className="btn btn-link text-right admin-sidebar-btn">
                                {
                                    this.props.sideBarState === "Expanded"
                                        ? <li className="far fa-chevron-double-left" onClick={(event) => this.props.onToggleMenuState(event)}></li>
                                        : <li className="far fa-chevron-double-right" onClick={(event) => this.props.onToggleMenuState(event)}></li>
                                }
                            </button>
                        </div>
                    </Fragment>
                    : null
            }
        </div>;
    }
}