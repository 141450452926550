import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminFormComboBoxComponent } from "../common/selltowebcombobox/SellToWebAdminFormComboBoxComponent";
import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";
import { SellToWebModalDialogHeaderButtonComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogHeaderButtonComponent";
import { SellToWebTableHeaderComponent } from "../common/selltowebtable/SellToWebTableHeaderComponent";
import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";

import { EVOXImagesMMREVOXMappingQueryComponent } from "./EVOXImagesMMREVOXMappingQueryComponent";
import { EVOXImagesComponent } from "./EVOXImagesComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

export class EVOXImagesMMREVOXMappingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showConfirmation: false,
            saveTableData: [],
            savePaintTable: false,
            saveSelectedYear: null,
            tableData: [],
            tableTitle: "",
            paintTable: false,
            vifData: null,
            vifDict: null,
            isLoadingData: false,
            imageType: "Front",
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null,
            selectedEVOXVehicleOEMColors: null,
            MMRPhotoRowData: null,
            MMRVehicleOEMColors: null,
            selectedMMRVehicleOEMColors: null,
            selectedYear: null,
            selectedMake: null
        };

        this.onVIFDataColumnCallback = this.onVIFDataColumnCallback.bind(this);
        this.onActionColumnCallback = this.onActionColumnCallback.bind(this);
        this.onMappedByColumnCallback = this.onMappedByColumnCallback.bind(this);
        this.onPhotoColumnCallback = this.onPhotoColumnCallback.bind(this);
        this.onMappingChange = this.onMappingChange.bind(this);
        this.onMappingClick = this.onMappingClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.onConfirmSaveAllClick = this.onConfirmSaveAllClick.bind(this);
        this.onCancelSaveAllClick = this.onCancelSaveAllClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onUndoClick = this.onUndoClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onCommentTextChange = this.onCommentTextChange.bind(this);
        this.onShowEVOXPhotosClick = this.onShowEVOXPhotosClick.bind(this);
        this.onCancelEVOXPhotosClick = this.onCancelEVOXPhotosClick.bind(this);
        this.onShowMMRPhotosClick = this.onShowMMRPhotosClick.bind(this);
        this.onCancelMMRPhotosClick = this.onCancelMMRPhotosClick.bind(this);
        this.onRowKeyDown = this.onRowKeyDown.bind(this);
        this.onVifNumberColumnCallback = this.onVifNumberColumnCallback.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        //console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        //console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "EVOX Images";
    evoxImageBaseUrl = "/EVOXImages/EVOXImage/";
    MMRImageBaseUrl = "https://cdn.dgdg.com/image/EVOX/";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/EVOXImages/MMREVOXMapping", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            this.getVifList();
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "componentDidMount");
        }
    }

    getVifList() {
        this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingComponent/getVifList", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        fetch("/EVOXImages/GetVIFList")
            .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
            .then(parsedResponse => {
                let vifData = parsedResponse.table;
                let vifDict = {};
                vifData.forEach((responseItem, index) => {
                    vifDict[responseItem.vifNumber.toString()] = responseItem;
                });
                this.setState({
                    vifData: vifData,
                    vifDict: vifDict
                });
            })
            .catch(notOKResponse => {
                if (notOKResponse.status === 500) {
                    notOKResponse.json()
                        .then(parsedError => {
                            this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetVIFList", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                            console.logError(parsedError, "EVOXImagesMMREVOXMappingComponent", "getVifList");
                            this.props.onShowAlert("danger", parsedError);
                        })
                        .catch(jsonParseError => {
                            console.logError(jsonParseError, "EVOXImagesMMREVOXMappingComponent", "getVifList");
                        });
                }
            });
    }

    onVIFDataColumnCallback(rowData) {
        try {
            let rowId = "vif_" + rowData.mmrId;
            let vifList = rowData.showMapping ? (rowData.vifList ? rowData.vifList : this.state.vifData.filter(data => data.year.toString() === rowData.year.toString())) : null;

            return <SellToWebAdminFormComboBoxComponent
                id={rowId} data={vifList} additionalData={rowData} itemKey="displayName" placeHolder="Type to filter vehicles" value={rowData.evoxDisplayName} colSpan="col-12" autoDrop
                onItemChange={this.onMappingChange} onItemClick={this.onMappingClick} onKeyDown={this.onRowKeyDown} />
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onVIFDataColumnCallback");
        }
    }

    onActionColumnCallback(rowData) {
        try {
            return <div className="form-inline">
                <button className="btn btn-link admin-tooltip" onClick={(event) => this.onSaveClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1" disabled={rowData.disabledSave}>
                    <i className="fas fa-save" />
                    <div className="content" data-display="static">
                        Save (Ctrl+S)
                    </div>
                </button>
                <button className="btn btn-link admin-tooltip" disabled={rowData.originalVIFNumber === rowData.vifNumber} onClick={(event) => this.onUndoClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1">
                    <i className="fas fa-undo" />
                    <div className="content" data-display="static">
                        Undo (Ctrl+U)
                    </div>
                </button>
                <button className="btn btn-link admin-tooltip" disabled={!rowData.vifNumber} onClick={(event) => this.onDeleteClick(event, rowData)} style={{ "padding": "8px 10px" }} tabIndex="-1">
                    <i className="fas fa-times text-danger" />
                    <div className="content" data-display="static">
                        Remove (Ctrl+R)
                    </div>
                </button>
                <SellToWebSpinnerComponent showSpinner={rowData.action_showSpinner} />
            </div>;
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onActionColumnCallback");
        }
    }

    onMappedByColumnCallback(rowData) {
        try {
            if (rowData.vifMappedBy) {
                return <div>
                    {rowData.vifMappedBy}<br />
                    <span className="text-nowrap">{rowData.vif_mapped_on ? "(" + CommonService.formatDateTime_2(moment(rowData.vif_mapped_on)) + ")" : ''}</span>
                </div>;
            }

            return "";
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onMappedByColumnCallback");
        }
    }

    onPhotoColumnCallback(rowData) {
        return <Fragment>
            {
                rowData.vifNumber
                    ? <button type="button" className="btn btn-link" onClick={(event) => this.onShowEVOXPhotosClick(event, rowData)} tabIndex="-1">
                        EVOX
                    </button>
                    : <button type="button" className="btn btn-link" disabled>
                        EVOX
                    </button>
            }
            &nbsp;|&nbsp;
            {
                rowData.imageCopiedDate
                    ? <button type="button" className="btn btn-link" onClick={(event) => this.onShowMMRPhotosClick(event, rowData)} tabIndex="-1">
                        CDN
                    </button>
                    : <button type="button" className="btn btn-link" disabled>
                        CDN
                    </button>
            }
        </Fragment>;
    }

    onMappingChange(id, filterValue) {
        try {
            let tableData = this.state.tableData;
            let mmrId = parseInt(id.split('_')[1]);
            let rowData = tableData.filter((row) => row.mmrId === mmrId)[0];
            let filteredData = [];
            if (this.state.vifData) {
                filteredData = this.state.vifData.filter(vifRow => {
                    return vifRow.displayName.toLowerCase().includes(filterValue.toLowerCase());
                });
            }

            rowData.vifList = filteredData;
            rowData.evoxDisplayName = filterValue;
            rowData.showMapping = true;
            if (!filterValue && rowData.originalVIFNumber) {
                rowData.rowCssName = "evox-images-bg-modified";
                rowData.vifNumber = "";
            }

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onMappingChange");
        }
    }

    onMappingClick(id, selectedItem) {
        try {
            let tableData = this.state.tableData;
            let mmrId = parseInt(id.split('_')[1]);
            let rowData = tableData.filter((row) => row.mmrId === mmrId)[0];

            rowData.vifList = null;
            rowData.vifNumber = selectedItem.vifNumber;
            rowData.showMapping = false;
            rowData.disabledSave = true;
            if (rowData.vifNumber) {
                let matchedVIF = this.state.vifDict[rowData.vifNumber.toString()];
                if (matchedVIF) {
                    rowData.disabledSave = false;
                    rowData.evoxDisplayName = matchedVIF.displayName;
                }
            }

            if (rowData.originalVIFNumber !== rowData.vifNumber) {
                rowData.rowCssName = "evox-images-bg-modified";
            }

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onMappingClick");
        }
    }

    onGetReportClick(selectedYear, selectedMake, selectedView, mmrId, vifNumber) {
        try {
            this.setState({ isLoadingData: true });
            let inputData = {
                year: selectedYear ? CommonService.formatYear(selectedYear) : null,
                makeName: selectedMake && selectedMake.make !== "All" ? selectedMake.make : null,
                view: selectedView ? selectedView.key : null,
                mmrId: mmrId,
                vifNumber: vifNumber
            };
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetMMREVOXMappings", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let tableData = parsedResponse.table;
                    let year, make;
                    tableData.forEach((rowData) => {
                        rowData.originalVIFNumber = rowData.vifNumber;
                        year = rowData.year;
                        make = rowData.makeName;
                        rowData.disabledSave = true;
                        if (!rowData.vifNumber) {
                            rowData.evoxDisplayName = rowData.year + " " + rowData.makeName + " " + rowData.modelName;
                        }

                        if (rowData.status === "Inactive") {
                            rowData.rowCssName = "evox-images-inactive-mapping";
                        }
                        else if (rowData.status === "Hidden") {
                            rowData.rowCssName = "evox-images-hidden";
                        }
                    });

                    this.setState({
                        isLoadingData: false,
                        selectedYear: year,
                        selectedMake: make,
                        tableData: CommonService.addOrdinalColumn(tableData),
                        tableTitle: "MMR EVOX Mapping - " + (selectedYear ? CommonService.formatYear(selectedYear) : "All"),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({ isLoadingData: false });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetMMREVOXMappings", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesMMREVOXMappingComponent", "onGetReportClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesMMREVOXMappingComponent", "onGetReportClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onGetReportClick");
        }
    }

    onSaveAllClick(selectedYear) {
        try {
            let saveTableData = [];
            saveTableData = this.state.tableData.filter(item => item.rowCssName === "evox-images-bg-modified");
            this.setState({
                showConfirmation: true,
                saveTableData: saveTableData,
                savePaintTable: true,
                saveSelectedYear: selectedYear
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onSaveAllClick");
        }
    }

    onConfirmSaveAllClick(event) {
        try {
            let saveData = [];
            this.state.saveTableData.forEach((rowData) => {
                rowData.action_showSpinner = true;
                saveData.push({
                    mmrId: rowData.mmrId,
                    vifNumber: rowData.vifNumber,
                    comment: rowData.comment
                });
            });

            if (saveData.length > 0) {
                this.setState({
                    isLoadingData: true,
                    paintTable: true
                });

                let inputData = saveData;
                this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingComponent/onConfirmSaveAllClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
                fetch("/EVOXImages/SaveMMREVOXMappings", {
                    method: "POST",
                    headers: {
                        'Content-Type': "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData)
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        this.state.saveTableData.forEach((saveTableRow) => {
                            saveTableRow.showUndo = saveTableRow.originalVIFNumber !== saveTableRow.vifNumber;
                            saveTableRow.action_showSpinner = false;
                            saveTableRow.rowCssName = null;
                            saveTableRow.vifMappedBy = saveTableRow.vifNumber ? this.props.remoteUpn : null;
                            saveTableRow.vif_mapped_on = saveTableRow.vifNumber ? moment() : null;
                        });

                        this.setState({
                            isLoadingData: false,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false })
                        });
                        this.onCancelSaveAllClick(event);
                    })
                    .catch(notOKResponse => {
                        this.state.saveTableData.forEach((saveTableRow) => {
                            saveTableRow.action_showSpinner = false;
                        });

                        this.setState({
                            isLoadingData: false,
                            paintTable: true
                        }, () => {
                            this.setState({ paintTable: false })
                        });
                        this.onCancelSaveAllClick(event);
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/onConfirmSaveAllClick", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                    console.logError(parsedError, "EVOXImagesMMREVOXMappingComponent", "onConfirmSaveAllClick");
                                    this.props.onShowAlert("danger", parsedError);
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "EVOXImagesMMREVOXMappingComponent", "onConfirmSaveAllClick");
                                });
                        }
                    });
            }
            else {
                this.setState({
                    paintTable: true
                }, () => {
                    this.setState({ paintTable: false })
                });
            }
        }
        catch (error) {
            this.setState({ isLoadingData: false });
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onConfirmSaveAllClick");
        }
    }

    onCancelSaveAllClick(event) {
        try {
            this.setState({
                showConfirmation: false,
                saveTableData: [],
                savePaintTable: true,
                saveSelectedYear: null
            }, () => {
                this.setState({ savePaintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onCancelSaveAllClick");
        }
    }

    onSaveClick(event, rowData) {
        try {
            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onSaveClick");
        }
    }

    onUndoClick(event, rowData) {
        try {
            rowData.rowCssName = null;
            rowData.vifNumber = rowData.originalVIFNumber;
            if (rowData.vifNumber) {
                let matchedVIF = this.state.vifDict[rowData.vifNumber.toString()];
                if (matchedVIF) {
                    rowData.evoxDisplayName = matchedVIF.displayName;
                }
            }
            else {
                rowData.evoxDisplayName = rowData.year + " " + rowData.makeName + " " + rowData.modelName;
            }

            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onUndoClick");
        }
    }

    onDeleteClick(event, rowData) {
        try {
            rowData.originalVIFNumber = rowData.vifNumber;
            rowData.action_showSpinner = true;
            rowData.vifNumber = "";
            rowData.evoxDisplayName = rowData.year + " " + rowData.makeName + " " + rowData.modelName;
            rowData.vifMappedBy = null;
            rowData.vif_mapped_on = null;

            this.setState({
                saveTableData: [rowData]
            }, this.onConfirmSaveAllClick);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onDeleteClick");
        }
    }

    onCommentTextChange(id, text, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.comment = text;
            rowData.rowCssName = "evox-images-bg-modified";
            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onCommentTextChange");
        }
    }

    onShowEVOXPhotosClick(event, rowData) {
        try {
            this.setState({
                evoxPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingComponent/onShowEVOXPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEVOXVehicleOEMColors/" + rowData.vifNumber)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            evoxVehicleOEMColors: parsedResponse,
                            selectedEVOXVehicleOEMColors: null
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            evoxVehicleOEMColors: sortedOEMColors,
                            selectedEVOXVehicleOEMColors: sortedOEMColors[0]
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEVOXVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesMMREVOXMappingComponent", "onShowEVOXPhotosClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesMMREVOXMappingComponent", "onShowEVOXPhotosClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onShowEVOXPhotosClick");
        }
    }

    onCancelEVOXPhotosClick(event) {
        this.setState({
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null
        });
    }

    onShowMMRPhotosClick(event, rowData) {
        try {
            this.setState({
                MMRPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingComponent/onShowMMRPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetMMRVehicleOEMColors/" + rowData.mmrId)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            MMRVehicleOEMColors: parsedResponse,
                            selectedMMRVehicleOEMColors: null
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            MMRVehicleOEMColors: sortedOEMColors,
                            selectedMMRVehicleOEMColors: sortedOEMColors[0]
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetMMRVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesMMREVOXMappingComponent", "onShowMMRPhotosClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesMMREVOXMappingComponent", "onShowMMRPhotosClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingComponent", "onShowMMRPhotosClick");
        }
    }

    onCancelMMRPhotosClick(event) {
        this.setState({
            MMRPhotoRowData: null,
            MMRVehicleOEMColors: null
        });
    }

    onRowKeyDown(event, rowData) {
        if (event.ctrlKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            switch (charCode) {
                case 's':
                    event.preventDefault();
                    this.onSaveClick(event, rowData);
                    break;

                case 'u':
                    if (rowData.vifNumber !== rowData.originalVIFNumber) {
                        event.preventDefault();
                        this.onUndoClick(event, rowData);
                    }
                    break;

                case 'r':
                    if (rowData.vifNumber) {
                        event.preventDefault();
                        this.onDeleteClick(event, rowData);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onVifNumberColumnCallback(rowData) {
        if (rowData.vifNumber)
            return <Link to={"/Global/EVOXImages/EVOXVehicles?vifNumber=" + rowData.vifNumber}>{rowData.vifNumber}</Link>;
    }

    render() {
        return <div className="admin-body admin-fill-content">
            <EVOXImagesComponent
                applicationInsights={this.props.applicationInsights}
                vehicleOEMColors={this.state.evoxVehicleOEMColors}
                photoRowData={this.state.evoxPhotoRowData}
                imageBaseUrl={this.evoxImageBaseUrl}
                vehicleIdPropertyName="vifNumber"
                imgUrlSeparator="/"
                imageExtension=""
                imageAlt="EVOX"
                imageContainer="download"
                imageBlobPrefix="EVOX/Image"
                titlePrefix="EVOX Photos"
                onCancelClick={this.onCancelEVOXPhotosClick}
                logPart2="EVOX image not found"
                onShowAlert={this.props.onShowAlert}
            />
            <EVOXImagesComponent
                applicationInsights={this.props.applicationInsights}
                vehicleOEMColors={this.state.MMRVehicleOEMColors}
                photoRowData={this.state.MMRPhotoRowData}
                imageBaseUrl={this.MMRImageBaseUrl}
                vehicleIdPropertyName="mmrId"
                imgUrlSeparator="."
                imageExtension=".png"
                imageAlt="CDN"
                imageContainer="image"
                imageBlobPrefix="EVOX"
                titlePrefix="CDN Photos"
                onCancelClick={this.onCancelMMRPhotosClick}
                logPart2="CDN image not found"
                onShowAlert={this.props.onShowAlert}
            />
            <SellToWebAdminModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.state.showConfirmation}
                className="admin-evox-mmr-save-dialog"
                title="Save MMR EVOX Mappings"
                onCancelClick={this.onCancelSaveAllClick}
            >
                <SellToWebModalDialogHeaderButtonComponent>
                    <button className="btn btn-primary" onClick={this.onConfirmSaveAllClick}>
                        Confirm <span className="fas fa-save admin-icon" />
                    </button>
                </SellToWebModalDialogHeaderButtonComponent>
                <div className="dgdg-report-body">
                    <SellToWebTableComponent applicationInsights={this.props.applicationInsights} cardCssName="dgdg-full-width" cardBlockCssName="overflow-auto" headerText={this.state.tableTitle} tableData={this.state.saveTableData} paintTable={this.state.savePaintTable}>
                        <SellToWebTableHeaderComponent position="PreHeader">
                            <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                                <td colSpan="2" className="text-center font-weight-bold">MMR</td>
                                <td colSpan="6" className="text-center font-weight-bold">EVOX</td>
                            </tr>
                        </SellToWebTableHeaderComponent>
                        <SellToWebTableRowComponent>
                            <SellToWebTableColumnComponent headerText="Id" dataColumn="mmrId" sortColumn="mmrId" filterColumn="mmrId" headerCssName="text-nowrap" />
                            <SellToWebTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" headerCssName="dgdg-sell-trade-MMR-ymmt-col" />
                            <SellToWebTableColumnComponent headerText="YMMT" dataColumn="evoxDisplayName" sortColumn="evoxDisplayName" filterColumn="evoxDisplayName" headerCssName="dgdg-sell-trade-evox-ymmt-col" />
                            <SellToWebTableColumnComponent headerText="Comment" dataColumn="comment" sortColumn="comment" filterColumn="comment" />
                            <SellToWebTableColumnComponent headerText="VIF" dataColumn="vifNumber" sortColumn="vifNumber" filterColumn="vifNumber" headerCssName="text-nowrap" />
                            <SellToWebTableColumnComponent headerText="Mapped By" sortColumn="vifMappedBy" filterColumn="vifMappedBy" headerCssName="text-nowrap" dataColumnCallback={this.onMappedByColumnCallback} />
                            <SellToWebTableColumnComponent headerText="Copied Date" dataColumn="imageCopiedDate" sortColumn="imageCopiedDate" filterColumn="imageCopiedDate" headerCssName="text-nowrap" dataType="date" />
                            <SellToWebTableColumnComponent headerText="Image Product" dataColumn="imageProductDownloaded" sortColumn="imageProductDownloaded" filterColumn="imageProductDownloaded" headerCssName="text-nowrap" />
                        </SellToWebTableRowComponent>
                    </SellToWebTableComponent>
                </div>
            </SellToWebAdminModalDialogComponent>
            <div className="d-flex flex-column admin-fill-content">
                <EVOXImagesMMREVOXMappingQueryComponent
                    applicationInsights={this.props.applicationInsights} selectedMake={this.state.selectedMake}
                    isLoadingData={this.state.isLoadingData} {...this.props} selectedYear={this.state.selectedYear}
                    onGetReportClick={this.onGetReportClick} onSaveAllClick={this.onSaveAllClick} onShowAlert={this.props.onShowAlert}
                />
                <br />
                <SellToWebTableComponent cardClassName="admin-flex-item" applicationInsights={this.props.applicationInsights} headerText={this.state.tableTitle} tableData={this.state.tableData} paintTable={this.state.paintTable}
                    tablePageSize={CommonService.tablePageSize} stickyHeader>
                    <SellToWebTableHeaderComponent position="PreHeader">
                        <tr className="dgdg-table-v3-header" style={{ "borderBottom": "1px solid #DCDCDC" }}>
                            <td />
                            <td colSpan="2" className="text-center font-weight-bold">MMR</td>
                            <td colSpan="8" className="text-center font-weight-bold">EVOX</td>
                        </tr>
                    </SellToWebTableHeaderComponent>
                    <SellToWebTableRowComponent>
                        <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <SellToWebTableColumnComponent headerText="MMR Vehicle Id" dataColumn="mmrId" sortColumn="mmrId" filterColumn="mmrId" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="YMMT" dataColumn="ymmt" sortColumn="ymmt" filterColumn="ymmt" headerCssName="dgdg-sell-trade-MMR-ymmt-col" />
                        <SellToWebTableColumnComponent headerText="YMMT" dataColumn="evoxDisplayName" sortColumn="evoxDisplayName" filterColumn="evoxDisplayName" dataColumnCallback={this.onVIFDataColumnCallback} headerCssName="dgdg-sell-trade-evox-ymmt-col" />
                        <SellToWebTableColumnComponent headerText="Action" headerCssName="dgdg-sell-trade-MMR-evox-action-col" dataColumnCallback={this.onActionColumnCallback} />
                        <SellToWebTableColumnComponent headerText="VIF" dataColumnCallback={this.onVifNumberColumnCallback} dataColumn="vifNumber" sortColumn="vifNumber" filterColumn="vifNumber" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="Date Delivered" dataColumn="dateDelivered" sortColumn="dateDelivered" filterColumn="dateDelivered" headerCssName="text-nowrap" dataType="date" />
                        <SellToWebTableColumnComponent headerText="Mapped By" dataColumn="vifMappedBy" sortColumn="vifMappedBy" filterColumn="vifMappedBy" headerCssName="text-nowrap" dataColumnCallback={this.onMappedByColumnCallback} />
                        <SellToWebTableColumnComponent headerText="Copied Date" dataColumn="imageCopiedDate" sortColumn="imageCopiedDate" filterColumn="imageCopiedDate" headerCssName="text-nowrap" dataType="date" />
                        <SellToWebTableColumnComponent headerText="Image Product" dataColumn="imageProductDownloaded" sortColumn="imageProductDownloaded" filterColumn="imageProductDownloaded" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="Images" cssName="text-nowrap" dataColumnCallback={this.onPhotoColumnCallback} />
                    </SellToWebTableRowComponent>
                </SellToWebTableComponent>
            </div>
        </div>;
    }
}