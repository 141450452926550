import React from "react";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";
import { SellToWebAdminDropdownComponent } from "../common/selltowebdropdown/SellToWebAdminDropdownComponent";
import { SellToWebAdminInputComponent } from "../common/selltowebinput/SellToWebAdminInputComponent";

export class EVOXImagesMMREVOXMappingQueryComponent extends React.Component {
    static YearCookie = "EVOXImagesMMREVOXMappingYearCookie";
    static MakeCookie = "EVOXImagesMMREVOXMappingMakeCookie";
    static ViewCookie = "EVOXImagesMMREVOXMappingViewCookie";

    viewData = [
        { key: "All", value: "All" },
        { key: "Mapped", value: "Mapped" },
        { key: "Unmapped", value: "Unmapped" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            years: CommonService.getYears("01/01/1998"),
            selectedYear: null,
            makes: null,
            selectedMake: null,
            selectedView: null,
            mmrVehicleId: null,
            vifNumber: null,
            isFirstLoad: true,
            isLoadingData: false
        };

        this.onYearClick = this.onYearClick.bind(this);
        this.onMakeClick = this.onMakeClick.bind(this);
        this.onViewClick = this.onViewClick.bind(this);
        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onSaveAllClick = this.onSaveAllClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        try {
            const params = new URLSearchParams(window.location.search);
            const newParams = new URLSearchParams();
            for (const [name, value] of params) {
                newParams.append(name.toLowerCase(), value);
            }

            if ((this.state.isFirstLoad || this.props.selectedYear) && this.state.selectedYear === null) {
                if (newParams.has("mmrid")) {
                    if (this.props.selectedYear) {
                        let selectedYear = moment(new Date((this.props.selectedYear).toString()));
                        this.onYearClick(null, selectedYear);
                    }

                    this.setState({
                        selectedView: this.viewData[0],
                        mmrVehicleId: newParams.get("mmrid"),
                        isFirstLoad: false
                    }, () => {
                        this.onGetReportClick();
                    });
                }
                else {
                    let selectedYear = this.state.years[0];
                    let cookieYear = CommonService.getCookie(EVOXImagesMMREVOXMappingQueryComponent.YearCookie);
                    if (newParams.has("year")) {
                        cookieYear = newParams.get("year");
                    }
                    if (cookieYear) {
                        let filterYear = this.state.years.filter((year) => year && year.isSame(cookieYear, 'date'));
                        if (filterYear.length === 1) {
                            selectedYear = filterYear[0];
                        }
                    }

                    let selectedView = this.viewData[0];
                    let cookieView = null;
                    if (!newParams.has("year") && !newParams.has("make")) {
                        cookieView = CommonService.getCookie(EVOXImagesMMREVOXMappingQueryComponent.ViewCookie);
                    }

                    if (cookieView) {
                        let filterView = this.viewData.filter((view) => view.key === cookieView);
                        if (filterView.length === 1) {
                            selectedView = filterView[0];
                        }
                    }

                    this.setState({
                        selectedYear: selectedYear,
                        selectedView: selectedView,
                        isFirstLoad: false
                    }, this.getMakes);
                }
            }

            if (!prevState.makes && this.state.makes && !newParams.has("mmrid")) {
                let cookieMake = CommonService.getCookie(EVOXImagesMMREVOXMappingQueryComponent.MakeCookie);
                if (newParams.has("make")) {
                    cookieMake = newParams.get("make");
                }

                let filterMake = cookieMake ? this.state.makes.filter(make => make.make.toLowerCase() === cookieMake.toLowerCase()) : null;
                if (filterMake && filterMake.length === 1) {
                    this.setState({
                        selectedMake: filterMake[0]
                    }, this.onGetReportClick);
                }
                else {
                    this.onGetReportClick();
                }
            }
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "componentDidUpdate");
        }
    }

    getMakes() {
        try {
            let inputData = {
                year: this.state.selectedYear ? CommonService.formatYear(this.state.selectedYear) : null
            };

            this.setState({
                isLoadingData: true
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesMMREVOXMappingQueryComponent/getMakes", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetVehicleMakes", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.items?.unshift({ makeId: null, make: "All" })
                    let selectedMake = parsedResponse.items.find(mke => mke.make === this.props.selectedMake);
                    this.setState({
                        makes: parsedResponse.items,
                        selectedMake: selectedMake ?? null,
                        isLoadingData: false
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetVehicleMakes", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesMMREVOXMappingQueryComponent", "getMakes");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesMMREVOXMappingQueryComponent", "getMakes");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "getMakes");
        }
    }

    onYearClick(id, year) {
        try {
            this.setState({
                selectedYear: year
            }, this.getMakes);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onYearClick");
        }
    }

    onMakeClick(id, make) {
        try {
            this.setState({ selectedMake: make });

        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onMakeClick");
        }
    }

    onViewClick(id, view) {
        try {
            this.setState({ selectedView: view });

        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onViewClick");
        }
    }

    onGetReportClick(event) {
        try {
            let cookieYear = this.state.selectedYear ? CommonService.formatServerDateTime(this.state.selectedYear) : null;
            CommonService.setCookie(EVOXImagesMMREVOXMappingQueryComponent.YearCookie, cookieYear, 30);
            CommonService.setCookie(EVOXImagesMMREVOXMappingQueryComponent.MakeCookie, this.state.selectedMake ? this.state.selectedMake.make : "", 30);
            CommonService.setCookie(EVOXImagesMMREVOXMappingQueryComponent.ViewCookie, this.state.selectedView ? this.state.selectedView.key : "", 30);
            this.props.onGetReportClick(this.state.selectedYear, this.state.selectedMake, this.state.selectedView, this.state.mmrVehicleId, this.state.vifNumber);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onGetReportClick");
        }
    }

    onSaveAllClick(event) {
        try {
            this.props.onSaveAllClick(this.state.selectedYear);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onSaveAllClick");
        }
    }

    onTextChange(id, value) {
        try {
            this.setState({ [id]: value }, this.onDisableSave);
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onTextChange");
        }
    }

    onKeyDown(event) {
        try {
            switch (event.keyCode) {
                case 13:
                    this.onGetReportClick(event);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "EVOXImagesMMREVOXMappingQueryComponent", "onKeyDown");
        }
    }

    render() {
        return <div className="card">
            <div className="card-block">
                <div className="row">
                    <SellToWebAdminDropdownComponent label="Select Year" data={this.state.years} displayFormat="Year" value={this.state.selectedYear ? this.state.selectedYear : "All"} onItemClick={this.onYearClick} />
                    <SellToWebAdminDropdownComponent label="Make" data={this.state.makes} itemKey="make" onItemClick={this.onMakeClick} value={this.state.selectedMake !== null ? this.state.selectedMake.make : "Select Make"} />
                    <SellToWebAdminDropdownComponent label="Mapped" data={this.viewData} itemKey="value" onItemClick={this.onViewClick} value={this.state.selectedView !== null ? this.state.selectedView.value : "Select View"} cssName="evox-images-separator" />
                    <SellToWebAdminInputComponent id="mmrVehicleId" label="MMR Vehicle Id" value={this.state.mmrVehicleId} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <SellToWebAdminInputComponent id="vifNumber" label="VIF" value={this.state.vifNumber} onChange={this.onTextChange} onKeyDown={this.onKeyDown} />
                    <div className="col-auto admin-query-btn">
                        <button disabled={this.props.isLoadingData || this.state.isLoadingData} className="btn btn-primary" onClick={(event) => this.onGetReportClick(event)}>
                            Get Report <i className="fas fa-redo-alt admin-icon" />
                        </button>
                        <button disabled={this.state.showSpinner} className="btn btn-primary admin-icon" onClick={this.onSaveAllClick}>
                            Save All<i className="fas fa-save admin-icon" />
                        </button>
                    </div>
                    <div style={{ "height": "33px", "width": "auto" }}>
                        <SellToWebSpinnerComponent showSpinner={this.props.isLoadingData || this.state.isLoadingData} />
                    </div>
                </div>
            </div>
        </div>;
    }
}