import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebDropdownComponent } from "../common/selltowebdropdown/SellToWebDropdownComponent";
import { SellToWebInputComponent } from "../common/selltowebinput/SellToWebInputComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleDetailsMMRComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trims: [{ description: { trim: "Select a Trim", disabled: true, selected: true } }],
            selectedTrim: null,
            exteriorColorOptions: [],
            selectedExteriorColor: null,
            mmrVehicleOEMColorOptions: [{ title: "Select a Paint Color", disabled: true, selected: true }],
            selectedMMRVehicleOEMColor: null,
            odometer: "",
            isVehicleConditionClicked: false,
            showSpinner: false,
            redirectTo: "",
            makePlusModel: "",
            mmrId: null,
            imageUrl: "",
            differentColorShown: false,
            showNoImageAvailable: false
        };

        this.onOptionDropdownChange = this.onOptionDropdownChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onOdometerChange = this.onOdometerChange.bind(this);
        this.onVehicleConditionClick = this.onVehicleConditionClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    imageBaseUrl = "https://cdn.acquirecars.com/image/EVOX/";
    componentDidMount() {
        try {
            let homeData = CommonService.getSessionStorage("homeData");
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-details", pageType: "Consumer", isLoggedIn: "No", properties: { vin: homeData?.vin, trim: homeData?.items[0]?.description?.trim, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (homeData) {
                CommonService.clientAzureStorageLog("VehicleDetailsMMRComponent", "componentDidMount", homeData?.vin, null, null, JSON.stringify(homeData), this.props.applicationInsights);

                let trimLocalStorage = CommonService.getLocalStorage("Home" + homeData?.vin + "SelectedTrim");
                let trims = homeData.items;
                trims = JSON.parse(JSON.stringify(trims));
                let makePlusModel = trims[0].description.year + " " + trims[0].description.make + " " + trims[0].description.model;
                let selectedTrim = null;
                if (trims.length === 1) {
                    selectedTrim = trims[0];
                }
                else {
                    let bestMatchTrim;
                    trims.forEach((trim) => {
                        if (trim.bestMatch) {
                            bestMatchTrim = trim;
                        }
                    });
                    if (bestMatchTrim) {
                        selectedTrim = bestMatchTrim;
                    }
                    else {
                        if (trimLocalStorage) {
                            selectedTrim = trims.find(trim => trim.description.trim === trimLocalStorage);
                        }
                        else {
                            trims.unshift({ description: { trim: "Select a Trim", disabled: true, selected: true } });
                        }
                    }
                }

                this.setState({ selectedTrim: selectedTrim, trims: trims, makePlusModel: makePlusModel }, () => {
                    if (selectedTrim) {
                        this.getMMRColors();
                    }
                });
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "componentDidMount");
        }
    }

    getMMRColors() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            this.props.applicationInsights.trackTrace({ message: "VehicleDetailsMMRComponent/getMMRColors", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleDetails/GetMMRColors", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.items.push({ "value": "Others" });
                    parsedResponse.items.forEach((item) => {
                        item.colorBox = CommonService.checkCSSColorSupports(item.value);
                        item.optionName = item.value;
                        if (item.value === "Others") {
                            item.colorBox = "White";
                        }
                    });
                    this.setState({
                        exteriorColorOptions: parsedResponse.items
                    }, () => {
                        this.getMMRDetails();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetMMRColors", ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleDetailsMMRComponent", "getMMRColors", homeData?.vin);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleDetailsMMRComponent", "getMMRColors", homeData?.vin);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "getMMRColors", homeData?.vin);
        }
    }

    getMMRDetails() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            let inputData = {
                id: null,
                year: (this.state.selectedTrim?.description?.year).toString(),
                makeName: this.state.selectedTrim?.description?.make,
                trimName: this.state.selectedTrim?.description?.trim
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleDetailsMMRComponent/getMMRDetails", properties: { tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleDetails/GetMMRId", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    this.setState({
                        mmrId: parsedResponse.table[0].mmrId
                    }, () => {
                        this.getOEMColors();
                    });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/getMMRDetails", ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleDetailsMMRComponent", "getMMRDetails", homeData?.vin);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleDetailsMMRComponent", "getMMRDetails", homeData?.vin);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "getMMRDetails", homeData?.vin);
        }
    }

    getOEMColors() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            let inputData = {
                vehicleId: this.state.mmrId.toString(),
                vin: homeData?.vin
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleDetailsMMRComponent/getOEMColors", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleDetails/GetKBBOemColors", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse && parsedResponse.length > 0) {
                        parsedResponse = [...parsedResponse].sort((a, b) => a.title > b.title ? 1 : -1);
                        parsedResponse.forEach((oemColor, index) => {
                            oemColor.colorBox = "#" + oemColor.rgb1;
                        });
                        let defaultColor = parsedResponse.find(x => x.simpletitle === "White");
                        if (!defaultColor) {
                            defaultColor = parsedResponse[0];
                        }

                        let otherColor = JSON.parse(JSON.stringify(defaultColor));
                        otherColor.title = "Other";
                        parsedResponse.push(otherColor);
                        this.setState({
                            mmrVehicleOEMColorOptions: parsedResponse
                        }, () => {
                            this.refreshVehicleOptions();
                        });
                    }
                    else {
                        this.setState({
                            mmrVehicleOEMColorOptions: this.state.exteriorColorOptions
                        }, () => {
                            this.refreshVehicleOptions();
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetKBBOemColors", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleDetailsMMRComponent", "getOEMColors", this.state.mmrId.toString() + " " + homeData?.vin);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleDetailsMMRComponent", "getOEMColors", this.state.mmrId.toString() + " " + homeData?.vin);
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsKBBComponent", "getOEMColors", this.state.mmrId.toString() + " " + homeData?.vin);
        }
    }

    refreshVehicleOptions() {
        let homeData = CommonService.getSessionStorage("homeData");
        try {
            let trimId = this.state.selectedTrim?.description?.trim;
            let imageUrl = this.imageBaseUrl + this.state.mmrId + ".front." + (this.state.selectedMMRVehicleOEMColor?.code ? this.state.selectedMMRVehicleOEMColor.code : "default") + ".png";
            let selectedExteriorColor = null, selectedMMRVehicleOEMColor = null, odometer = "", differentColorShown = false, showNoImageAvailable = false;
            let lsSelectedMMRVehicleOEMColorCode = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedMMRVehicleOEMColorCode");
            let lsSelectedMMRVehicleColorName = CommonService.getLocalStorage("VehicleDetails" + trimId + "SelectedMMRVehicleColorName");
            if (lsSelectedMMRVehicleOEMColorCode) {
                selectedMMRVehicleOEMColor = this.state.exteriorColorOptions.find(exteriorColor => exteriorColor.code === lsSelectedMMRVehicleOEMColorCode);
            }
            else {
                if (lsSelectedMMRVehicleColorName) {
                    selectedMMRVehicleOEMColor = this.state.exteriorColorOptions.find(exteriorColor => exteriorColor.value === lsSelectedMMRVehicleColorName);
                }
            }
            selectedMMRVehicleOEMColor = this.state.exteriorColorOptions.find(exteriorColor => exteriorColor.code === lsSelectedMMRVehicleOEMColorCode);
            let lsOdometer = CommonService.getLocalStorage("VehicleDetails" + trimId + "Odometer");
            if (lsOdometer) {
                odometer = lsOdometer;
            }

            let lsImageUrl = CommonService.getLocalStorage("VehicleDetails" + trimId + "ImageUrl");
            if (lsImageUrl) {
                imageUrl = lsImageUrl;
            }

            let lsDifferentColorShown = CommonService.getLocalStorage("VehicleDetails" + trimId + "DifferentColorShown");
            if (lsDifferentColorShown) {
                differentColorShown = lsDifferentColorShown;
            }

            let lsShowNoImageAvailable = CommonService.getLocalStorage("VehicleDetails" + trimId + "NoImageAvailable");
            if (lsShowNoImageAvailable) {
                showNoImageAvailable = lsShowNoImageAvailable;
            }
            selectedExteriorColor = this.state.exteriorColorOptions.find(colorOption => {
                if (selectedMMRVehicleOEMColor && selectedMMRVehicleOEMColor.simpletitle) {
                    return colorOption.value.toLowerCase() === selectedMMRVehicleOEMColor.simpletitle.toLowerCase();
                }
                return false;
            });
            if (selectedExteriorColor === undefined) {
                selectedExteriorColor = this.state.exteriorColorOptions.find(eCP => eCP.value === "White");
            }
            this.setState({
                selectedExteriorColor: selectedExteriorColor,
                selectedKBBVehicleOEMColor: selectedMMRVehicleOEMColor,
                odometer: odometer,
                imageUrl: imageUrl,
                differentColorShown: differentColorShown,
                showNoImageAvailable: showNoImageAvailable
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "refreshVehicleOptions", this.state.selectedTrim?.description?.trim + " " + homeData?.vin);
        }
    }


    updateTrimChange(id, selectedOption) {
        try {
            this.setState({
                [id]: this.state.trims.find(x => x.description.trim === selectedOption.description.trim),
                isVehicleConditionClicked: false,
            }, () => {
                this.getMMRColors();
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "updateTrimChange", id);
        }
    }

    updateColorChange(id, selectedOption) {
        try {
            this.setState({ [id]: selectedOption, differentColorShown: false, showNoImageAvailable: false }, () => {
                this.updateSelectedVehicleColor(this.state.selectedMMRVehicleOEMColor !== null ? this.state.selectedMMRVehicleOEMColor : selectedOption);
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "updateColorChange", id);
        }
    }

    updateSelectedVehicleColor(selectedMMRColor) {
        try {
            let imageUrl = this.imageBaseUrl + this.state.mmrId + ".front." + (this.state.selectedMMRVehicleOEMColor ? this.state.selectedMMRVehicleOEMColor.code : "default") + ".png";
            let exteriorColorOptions = this.state.exteriorColorOptions;
            if (exteriorColorOptions && selectedMMRColor.simpletitle) {
                let mappedExteriorColor = exteriorColorOptions.find(eCP => eCP.value.toLowerCase() === selectedMMRColor.simpletitle.toLowerCase());
                if (mappedExteriorColor) {
                    this.setState(
                        {
                            selectedExteriorColor: mappedExteriorColor,
                            imageUrl: imageUrl,
                            differentColorShown: false,
                            showNoImageAvailable: false
                        });
                }
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "updateSelectedVehicleColor", selectedMMRColor);
        }
    }

    onOptionDropdownChange(id, selectedOption) {
        try {
            switch (id) {
                case "selectedTrim":
                    this.updateTrimChange(id, selectedOption);
                    break;
                case "selectedMMRVehicleOEMColor":
                    this.updateColorChange(id, selectedOption);
                    break;
                default:
                    break;
            }
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "onOptionDropdownChange", id);
        }
    }

    onOdometerChange(id, value) {
        try {
            let formattedValue = value.replaceAll(",", "").replace(/[^0-9]/g, "");
            this.setState({
                [id]: formattedValue
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "onOdometerChange", id);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onVehicleConditionClick();
        }
    }

    onBackButtonClick() {
        window.history.go(-1);
    }

    onVehicleConditionClick() {
        try {
            let homeData = CommonService.getSessionStorage("homeData");
            let offerSession = CommonService.getSessionStorage("offerSession");
            let inputsValid = this.state.selectedTrim?.description.trim !== "Select a Trim" && this.state.selectedMMRVehicleOEMColor && this.state.selectedMMRVehicleOEMColor?.title !== "Select a Paint Color" && this.state.odometer;

            if (inputsValid) {

                this.setState({ showSpinner: true });
                let inputData = {
                    Vin: homeData?.vin
                };
                this.props.applicationInsights.trackTrace({ message: "VehicleDetailsRouteComponent/onVehicleConditionClick", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                fetch("/VehicleEquipment/GetAutoCheckData", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        if (parsedResponse.errors || parsedResponse.status === "Error") {
                            this.setState({
                                redirectTo: "/unique-vehicle",
                                showSpinner: false
                            });
                        }
                        else {
                            let vehicleDetailsData = {
                                trims: this.state.trims,
                                selectedTrim: this.state.selectedTrim,
                                mmrVehicleOEMColorOptions: this.state.mmrVehicleOEMColorOptions,
                                selectedExteriorColor: this.state.selectedExteriorColor,
                                exteriorColorOptions: this.state.exteriorColorOptions,
                                selectedVehicleOEMColor: this.state.selectedMMRVehicleOEMColor,
                                odometer: this.state.odometer,
                                vin: homeData?.vin,
                                licensePlate: offerSession?.vehicleDetail?.licensePlate === undefined ? "" : offerSession.vehicleDetail.licensePlate,
                                licensePlateState: offerSession?.vehicleDetail?.licensePlateState === undefined ? "" : offerSession.vehicleDetail.licensePlateState,
                                makePlusModel: this.state.makePlusModel,
                                imageUrl: this.state.imageUrl,
                                differentColorShown: this.state.differentColorShown
                            };
                            let autocheckData = {
                                autocheckResponse: parsedResponse.autocheckResponse,
                                accidentCount: parsedResponse.accidentCount,
                                ownerCount: parsedResponse.ownerCount,
                                accidentDetails: parsedResponse.accidentDetails,
                                useAutocheck: parsedResponse.useAutocheck
                            }
                            vehicleDetailsData["autocheckData"] = autocheckData;
                            CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);

                            offerSession.vehicleDetail.year = this.state.selectedTrim.description.year;
                            offerSession.vehicleDetail.make = this.state.selectedTrim.description.make;
                            offerSession.vehicleDetail.model = this.state.selectedTrim.description.model;
                            offerSession.vehicleDetail.trim = this.state.selectedTrim.description.trim;
                            offerSession.vehicleDetail.odometer = this.state.odometer;
                            offerSession.vehicleDetail.colorSimpleTitle = this.state.selectedMMRVehicleOEMColor.simpletitle ? this.state.selectedMMRVehicleOEMColor.simpletitle : this.state.selectedMMRVehicleOEMColor.value;
                            offerSession.vehicleDetail.colorCode = this.state.selectedMMRVehicleOEMColor.code;
                            offerSession.vehicleDetail.colorTitle = this.state.selectedMMRVehicleOEMColor.title;
                            offerSession.vehicleDetail.colorRgb1 = this.state.selectedMMRVehicleOEMColor.rgb1;
                            offerSession.vehicleDetail.colorRgb2 = this.state.selectedMMRVehicleOEMColor.rgb2;

                            offerSession.vehicleHistory = {};
                            offerSession.vehicleHistory.owners = parsedResponse.ownerCount;
                            offerSession.vehicleHistory.accidentCount = parsedResponse.accidentCount;
                            offerSession.vehicleHistory.autocheckResponse = parsedResponse.autocheckResponse;
                            offerSession.vehicleHistory.useAutocheck = parsedResponse.useAutocheck;
                            CommonService.setSessionStorage("offerSession", offerSession);

                            CommonService.setLocalStorage("Home" + homeData?.vin + "SelectedTrim", this.state.selectedTrim?.description?.trim);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "SelectedMMRVehicleOEMColorCode", this.state.selectedMMRVehicleOEMColor.code ? this.state.selectedMMRVehicleOEMColor.code : "");
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "SelectedMMRVehicleColorName", this.state.selectedMMRVehicleOEMColor.value ? this.state.selectedMMRVehicleOEMColor.value : "");
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "ImageUrl", this.state.imageUrl);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "DifferentColorShown", this.state.differentColorShown);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "NoImageAvailable", this.state.showNoImageAvailable);
                            CommonService.setLocalStorage("VehicleDetails" + this.state.selectedTrim?.description?.trim + "Odometer", this.state.odometer);

                            if (parsedResponse.accidentCount.toString() === "0") {
                                this.setState({ redirectTo: "/vehicle-condition" });
                            }
                            else {
                                this.setState({ redirectTo: "/vehicle-history" });
                            }
                        }
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleDetails/GetAutoCheckData", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                    console.logError(parsedError, "VehicleDetailsRouteComponent", "onVehicleConditionClick");
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "VehicleDetailsRouteComponent", "onVehicleConditionClick");
                                });
                        }
                    });
            }

            this.setState({
                isVehicleConditionClicked: true
            });
        }
        catch (error) {
            console.logError(error, "VehicleDetailsMMRComponent", "onVehicleConditionClick", this.state.selectedTrim?.description.trim);
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let homeData = CommonService.getSessionStorage("homeData");
        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Details", "Default", "Body Html")?.Value ?? "";
        return <Fragment>
            <SellToWebTopPanelComponent imageUrl={this.state.imageUrl} makePlusModel={this.state.makePlusModel} trim={this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim" ? this.state.selectedTrim?.description?.trim : "–"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehicledetails-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="consumer-vehicledetails-form">
                        <SellToWebDropdownComponent id="selectedTrim" className="consumer-vehicledetails-form-trim" label="Trim" isChecked={(this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim")} data={this.state.trims} itemKey="description.trim" value={this.state.selectedTrim !== null ? this.state.selectedTrim?.description?.trim : "Select a Trim"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleConditionClicked && this.state.selectedTrim === null}></SellToWebDropdownComponent>
                        <SellToWebDropdownComponent id="selectedMMRVehicleOEMColor" className={"consumer-vehicledetails-form-oem" + (!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : "")} classNameItem="app-fw-500" label="Exterior Color" isChecked={(this.state.selectedMMRVehicleOEMColor && this.state.selectedMMRVehicleOEMColor?.title !== "Select a Paint Color")} disabled={!this.state.selectedTrim} data={this.state.mmrVehicleOEMColorOptions} itemKey={this.state.mmrVehicleOEMColorOptions[0].title ? "title" : "value"} value={this.state.selectedMMRVehicleOEMColor !== null ? this.state.selectedMMRVehicleOEMColor?.title ? this.state.selectedMMRVehicleOEMColor?.title : this.state.selectedMMRVehicleOEMColor?.value : "Select a Paint Color"} onSelectChange={this.onOptionDropdownChange} isError={this.state.isVehicleConditionClicked && !this.state.selectedMMRVehicleOEMColor} colorBox={this.state.selectedMMRVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.selectedMMRVehicleOEMColor?.colorBox) : null}></SellToWebDropdownComponent>
                        <SellToWebInputComponent id="odometer" appendText="Miles" isChecked={this.state.odometer} className={!this.state.selectedTrim ? " consumer-vehicledetails-form-opacity" : ""} disabled={!this.state.selectedTrim} value={this.state.odometer ? CommonService.formatMoney(this.state.odometer) : ""} placeholder="Enter Your Current Mileage" onTextChange={this.onOdometerChange} onKeyDown={this.handleKeyDown} imagePath="images/miles-input.svg" isError={this.state.isVehicleConditionClicked && !this.state.odometer} />
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onVehicleConditionClick} showSpinner={this.state.showSpinner} value="Vehicle Condition" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={homeData?.vin} makePlusModel={this.state.makePlusModel} valuationMethod={"MMR"}
                        imageUrl={this.state.imageUrl} differentColorShown={this.state.differentColorShown} showNoImageAvailable={this.state.showNoImageAvailable} odometer={CommonService.formatMoney(this.state.odometer)}
                        colorBox={this.state.selectedMMRVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.selectedMMRVehicleOEMColor?.colorBox) : null}
                        colorName={this.state.selectedMMRVehicleOEMColor?.title ? this.state.selectedMMRVehicleOEMColor.title : this.state.selectedMMRVehicleOEMColor?.value}
                        trim={this.state.selectedTrim && this.state.selectedTrim?.description?.trim !== "Select a Trim" ? this.state.selectedTrim?.description?.trim : "–"}
                        onMissingImage={this.onMissingImage} siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
        </Fragment>;
    }
}