import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebTableColumnComponent } from "../common/selltowebtable/SellToWebTableColumnComponent";
import { SellToWebTableComponent } from "../common/selltowebtable/SellToWebTableComponent";
import { SellToWebTableRowComponent } from "../common/selltowebtable/SellToWebTableRowComponent";
import { SellToWebAdminToggleComponent } from "../common/selltowebcheckbox/SellToWebAdminToggleComponent";
import { SellToWebSpinnerComponent } from "../common/selltowebspinner/SellToWebSpinnerComponent";

import { EVOXImagesEVOXVehiclesQueryComponent } from "./EVOXImagesEVOXVehiclesQueryComponent";
import { EVOXImagesComponent } from "./EVOXImagesComponent";

export class EVOXImagesEVOXVehiclesComponent extends React.Component {
    statusList = ["Active", "Hidden"];

    constructor(props) {
        super(props);
        this.state = {
            isLoadingData: false,
            paintTable: false,
            tableData: []
        };

        this.onGetReportClick = this.onGetReportClick.bind(this);
        this.onYMMTColumnCallback = this.onYMMTColumnCallback.bind(this);
        this.onPhotoColumnCallback = this.onPhotoColumnCallback.bind(this);
        this.onShowEVOXPhotosClick = this.onShowEVOXPhotosClick.bind(this);
        this.onCancelEVOXPhotosClick = this.onCancelEVOXPhotosClick.bind(this);
        this.onCopyTableCallBack = this.onCopyTableCallBack.bind(this);
        this.onVehicleIdColumnCallback = this.onVehicleIdColumnCallback.bind(this);
        this.onIsActiveChange = this.onIsActiveChange.bind(this);
        this.onStatusColumnCallback = this.onStatusColumnCallback.bind(this);

        props.onPageChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        //console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        //console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    pageTitle = "EVOX Vehicles";
    evoxImageBaseUrl = "/EVOXImages/EVOXImage/";

    componentDidMount() {
        try {
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/Global/EVOXImages/MMREVOXMapping", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "componentDidMount");
        }
    }

    onGetReportClick(vifNumber, selectedView) {
        try {
            this.setState({ isLoadingData: true });

            let inputData = {
                vifNumber: vifNumber,
                view: selectedView ? selectedView.key : null
            };

            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onGetReportClick", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEvoxVehiclesData", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    parsedResponse.table.forEach((responseItem, index) => {
                        responseItem.evoxDisplayName = responseItem.ymmt + " "
                            + (responseItem.body ? responseItem.body : "") + " ("
                            + responseItem.vifNumber + ", "
                            + responseItem.imageProductDownloaded + ", "
                            + CommonService.formatDate(responseItem.dateDelivered) + ")";

                        if (responseItem.status === "Inactive") {
                            responseItem.rowCssName = "evox-images-inactive-mapping";
                        }
                        else if (responseItem.status === "Hidden") {
                            responseItem.rowCssName = "evox-images-hidden";
                        }

                        responseItem.is_active = responseItem.status === "Active";
                        responseItem.is_active_label = "Hidden";
                        responseItem.is_active_label2 = "Active";
                    });
                    this.setState({
                        isLoadingData: false,
                        tableData: CommonService.addOrdinalColumn(parsedResponse.table),
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    this.setState({
                        isLoadingData: false,
                        tableData: [],
                        paintTable: true,
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEvoxVehiclesData", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesEVOXVehiclesComponent", "onGetReportClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesEVOXVehiclesComponent", "onGetReportClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "onGetReportClick");
        }
    }

    onYMMTColumnCallback(rowData) {
        return <Link to={"/Global/EVOXImages/MMREVOXMapping?year=" + rowData.year + "&make=" + rowData.make}>{rowData.ymmt}</Link>;
    }

    onPhotoColumnCallback(rowData) {
        if (rowData.lastImageDownloadDate) {
            return <button type="button" className="btn btn-link" onClick={(event) => this.onShowEVOXPhotosClick(event, rowData)} tabIndex="-1">
                EVOX
            </button>;
        }

        return <button type="button" className="btn btn-link" disabled>
            EVOX
        </button>;
    }

    onShowEVOXPhotosClick(event, rowData) {
        try {
            this.setState({
                evoxPhotoRowData: rowData,
                imageType: "Front"
            });
            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onShowEVOXPhotosClick", properties: { logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/GetEVOXVehicleOEMColors/" + rowData.vifNumber)
                .then(response => { if (response.ok) { return response.json(); } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.status) {
                        this.setState({
                            evoxVehicleOEMColors: parsedResponse
                        });
                    }
                    else {
                        let sortedOEMColors = CommonService.sortJsonData(parsedResponse, "whiteness", "desc");
                        this.setState({
                            evoxVehicleOEMColors: sortedOEMColors
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/GetEVOXVehicleOEMColors", ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesEVOXVehiclesComponent", "onShowEVOXPhotosClick");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesEVOXVehiclesComponent", "onShowEVOXPhotosClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "onShowEVOXPhotosClick");
        }
    }

    onIsActiveChange(id, isChecked, rowData) {
        try {
            let tableData = this.state.tableData;
            rowData.is_active = isChecked;
            rowData.is_active_showSpinner = true;
            rowData.status = isChecked ? "Active" : "Hidden";

            this.setState({
                tableData: tableData,
                paintTable: true
            }, () => {
                this.setState({ paintTable: false });
            });

            let inputData = {
                vifNumber: rowData.vifNumber,
                status: rowData.status
            };

            this.props.applicationInsights.trackTrace({ message: "/EVOXImagesEVOXVehiclesComponent/onIsActiveChange", properties: { ...inputData, logon: this.props.remoteUpn, position_code: this.props.positionCode } });
            fetch("/EVOXImages/SaveEVOXVehicleStatus", {
                method: "POST",
                headers: {
                    'Content-Type': "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData)
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    rowData.rowCssName = "";
                    if (rowData.status === "Inactive") {
                        rowData.rowCssName = "evox-images-inactive-mapping";
                    }
                    else if (rowData.status === "Hidden") {
                        rowData.rowCssName = "evox-images-hidden";
                    }
                    rowData.is_active_showSpinner = false;
                    this.setState({
                        tableData: tableData,
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                })
                .catch(notOKResponse => {
                    rowData.is_active_showSpinner = false;
                    this.setState({
                        paintTable: true
                    }, () => {
                        this.setState({ paintTable: false });
                    });
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/EVOXImages/SaveEVOXVehicleStatus", ...inputData, ...parsedError, logon: this.props.remoteUpn ?? "", positionCode: this.props.positionCode ?? "" } });
                                console.logError(parsedError, "EVOXImagesEVOXVehiclesComponent", "onIsActiveChange");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "EVOXImagesEVOXVehiclesComponent", "onIsActiveChange");
                            });
                    }
                });

        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "onIsActiveChange");
        }
    }

    onCancelEVOXPhotosClick(event) {
        this.setState({
            evoxPhotoRowData: null,
            evoxVehicleOEMColors: null
        });
    }

    onCopyTableCallBack(event) {
        try {
            this.props.onShowAlert("success", { message: "Copied to Clipboard" }, 4000);
            CommonService.clientAzureStorageLog("EVOXImagesEVOXVehiclesComponent", "onCopyTableCallBack", null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "onCopyTableCallBack");
        }
    }

    onVehicleIdColumnCallback(rowData) {
        if (rowData.mmrId)
            return <Link to={"/Global/EVOXImages/MMREVOXMapping?mmrId=" + rowData.mmrId}>{rowData.mmrId}</Link>;
    }

    onStatusColumnCallback(rowData) {
        try {
            if (rowData.status !== "Inactive") {
                return <Fragment>
                    <SellToWebAdminToggleComponent id={"is_active-" + rowData[CommonService.ordinalColumnName]} additionalData={rowData} value={rowData.is_active} label={rowData.is_active_label} label2={rowData.is_active_label2} onChange={this.onIsActiveChange} cssName="dgdg-sell-trade-MMR-evox-image-status" />
                    {rowData.is_active_showSpinner ? <SellToWebSpinnerComponent cssName="float-right" showSpinner /> : null}
                </Fragment>
            }
            else {
                return rowData.status;
            }

        }
        catch (error) {
            console.logError(error, "EVOXImagesEVOXVehiclesComponent", "onStatusColumnCallback");
        }
    }

    render() {
        let evoxOEMColors = [];
        if (this.state.evoxVehicleOEMColors && !this.state.evoxVehicleOEMColors.status) {
            this.state.evoxVehicleOEMColors.forEach((oemColor) => {
                let rgbColor = "#" + oemColor.rgb1;
                evoxOEMColors.push(<div className="d-inline-block dgdg-sell-trade-MMR-evox-oem-color" style={{ "backgroundColor": rgbColor }} role="button" onClick={(event) => this.onEVOXVehicleOEMColorsClick(event, oemColor)} />);
            });
        }

        return <div className="d-flex flex-column admin-fill-content admin-body">
            <EVOXImagesComponent
                vehicleOEMColors={this.state.evoxVehicleOEMColors}
                photoRowData={this.state.evoxPhotoRowData}
                imageBaseUrl={this.evoxImageBaseUrl}
                vehicleIdPropertyName="vifNumber"
                imgUrlSeparator="/"
                imageExtension=""
                imageAlt="EVOX"
                imageContainer="download"
                imageBlobPrefix="EVOX/Image"
                titlePrefix="EVOX Photos"
                onCancelClick={this.onCancelEVOXPhotosClick}
                logPart2="EVOX image not found"
                onShowAlert={this.props.onShowAlert}
                applicationInsights={this.props.applicationInsights}
            />
            <EVOXImagesEVOXVehiclesQueryComponent
                isLoadingData={this.state.isLoadingData}
                {...this.props}
                onGetReportClick={this.onGetReportClick} />
            <br />
            <div className="admin-flex-item">
                <SellToWebTableComponent applicationInsights={this.props.applicationInsights} headerText="EVOX Vehicles" tableData={this.state.tableData} paintTable={this.state.paintTable} tablePageSize={CommonService.tablePageSize} stickyHeader
                    copyTableConfig={{ "columns": [0, 1, 2, 3, 4, 5, 6, 7], onCopyTableCallBack: this.onCopyTableCallBack }}
                >
                    <SellToWebTableRowComponent>
                        <SellToWebTableColumnComponent headerText="#" dataColumn={CommonService.ordinalColumnName} dataType="number" sortColumn={CommonService.ordinalColumnName} />
                        <SellToWebTableColumnComponent headerText="VIF Number" dataColumn="vifNumber" filterColumn="vifNumber" sortColumn="vifNumber" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="Mapped MMR Vehicle Id" dataColumnCallback={this.onVehicleIdColumnCallback} dataColumn="mmrId" filterColumn="mmrId" sortColumn="mmrId" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="Status" dataColumn="status" dataColumnCallback={this.onStatusColumnCallback} />
                        <SellToWebTableColumnComponent headerText="YMMT" dataColumn="ymmt" filterColumn="ymmt" sortColumn="ymmt" dataColumnCallback={this.onYMMTColumnCallback} />
                        <SellToWebTableColumnComponent headerText="Date Delivered" dataColumn="dateDelivered" sortColumn="dateDelivered" filterColumn="dateDelivered" headerCssName="text-nowrap" dataType="date" />
                        <SellToWebTableColumnComponent headerText="Image Download Date" dataColumn="lastImageDownloadDate" sortColumn="lastImageDownloadDate" filterColumn="lastImageDownloadDate" headerCssName="text-nowrap" dataType="date" />
                        <SellToWebTableColumnComponent headerText="Image Product" dataColumn="imageProductDownloaded" sortColumn="imageProductDownloaded" filterColumn="imageProductDownloaded" headerCssName="text-nowrap" />
                        <SellToWebTableColumnComponent headerText="Images" cssName="text-nowrap" dataColumnCallback={this.onPhotoColumnCallback} />
                    </SellToWebTableRowComponent>
                </SellToWebTableComponent>
            </div>
        </div>;
    }
}
