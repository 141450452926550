import React, { Component, Fragment } from "react";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";
import { SellToWebModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebModalDialogComponent";

export class VehicleOfferRouteComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            offerSession: null,
            showSpinner: false,
            redirectTo: "",
            showTerms: false,
            showEmailText: false,
            showDialog: false
        };

        this.onConfirmOfferClick = this.onConfirmOfferClick.bind(this);
        this.onThinkingAbtClick = this.onThinkingAbtClick.bind(this);
        this.onDismissModalClick = this.onDismissModalClick.bind(this);
        this.toggleTermsNConditions = this.toggleTermsNConditions.bind(this);
        this.onThinkingAbtBtnClick = this.onThinkingAbtBtnClick.bind(this);
    }

    pageTitle = "Vehicle Offer";

    componentDidMount() {
        let offerNumber = null;
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            const params = new URLSearchParams(window.location.search)
            const newParams = new URLSearchParams();
            for (const [name, value] of params) {
                newParams.append(name.toLowerCase(), value);
            }

            if (newParams.has("offernumber")) {
                offerNumber = newParams.get("offernumber");
                this.readOfferApi(offerNumber);
            }
            else {
                let offerSession = CommonService.getSessionStorage("offerSession");
                if (offerSession) {
                    offerNumber = offerSession.header.offerNumber;
                    this.setState({
                        offerSession: offerSession,
                        showEmailText: true
                    });
                }
                else {
                    this.setState({ redirectTo: "/offer-error" });
                }
            }

            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-offer", pageType: "Consumer", isLoggedIn: "No", properties: { offerNumber: offerNumber, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            CommonService.clientAzureStorageLog("VehicleOfferRouteComponent", "componentDidMount", offerNumber, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "VehicleOfferRouteComponent", "componentDidMount", offerNumber);
        }
    }

    pageLoadedTime = new Date();

    readOfferApi(offerNumber) {
        try {
            let inputData = {
                offerNumber: offerNumber
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleOfferRouteComponent/readOfferApi", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleOffer/ReadOffer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    let readOfferResponse = parsedResponse;
                    if (parsedResponse.errorMessage) {
                        CommonService.clientAzureStorageLog("VehicleOfferRouteComponent", "readOfferApi", "fetch ReadOffer " + inputData?.offerNumber, "Redirect to offer-error", parsedResponse.errorMessage, null, this.props.applicationInsights);
                        this.setState({ redirectTo: "/offer-error" });
                    }
                    else if (readOfferResponse.offer.status === "Confirmed") {
                        this.setState({ redirectTo: "/confirmation/?offernumber=" + readOfferResponse?.offer.offerNumber });
                    }
                    else {
                        this.setState({ offerSession: readOfferResponse });
                    }
                    this.props.applicationInsights.trackEvent({ message: "VehicleOfferRouteComponent/readOfferApi", properties: { offerNumber: offerNumber, vin: readOfferResponse?.vin, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleOffer/ReadOffer", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleOfferRouteComponent", "readOfferApi");
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleOfferRouteComponent", "readOfferApi");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleOfferRouteComponent", "readOfferApi");
        }
    }

    onThinkingAbtClick(event) {
        try {
            CommonService.clientAzureStorageLog("VehicleOfferRouteComponent", "onThinkingAbtClick", this.state.offerSession?.offer?.offerNumber, null, null, null, this.props.applicationInsights);
            this.setState({ showDialog: true });
        }
        catch (error) {
            console.logError(error, "VehicleOfferRouteComponent", "onThinkingAbtClick");
        }
    }

    onDismissModalClick() {
        try {
            this.setState({ showDialog: false });
        }
        catch (error) {
            console.logError(error, "VehicleOfferRouteComponent", "onDismissModalClick");
        }
    }

    onConfirmOfferClick() {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                vin: this.state.offerSession?.vehicleDetail?.vin,
                offerNumber: this.state.offerSession?.offer?.offerNumber
            };
            const now = new Date();
            const msBetweenDates = Math.abs(this.pageLoadedTime.getTime() - now.getTime());
            const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            if (hoursBetweenDates < 24) {
                this.props.applicationInsights.trackTrace({ message: "VehicleOfferRouteComponent/onConfirmOfferClick", properties: { ...inputData, vehicleId: this.state.offerSession?.vehicleDetail?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                fetch("/Api/ConfirmOffer", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8;"
                    },
                    body: JSON.stringify(inputData),
                    credentials: "same-origin"
                })
                    .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                    .then(parsedResponse => {
                        if (parsedResponse.errors) {
                            CommonService.clearLocalStorage(this.state.offerSession?.vehicleDetail.vin, this.state.offerSession?.vehicleDetail.vehicleId ?? this.state.offerSession?.vehicleDetail.selectedTrim.description.trim);
                            this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
                        }
                        else {
                            this.setState({ showSpinner: false, redirectTo: "/confirmation/?offernumber=" + this.state.offerSession?.offer.offerNumber });
                        }
                    })
                    .catch(notOKResponse => {
                        if (notOKResponse.status === 500 || notOKResponse.status === 400) {
                            notOKResponse.json()
                                .then(parsedError => {
                                    this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/Api/ConfirmOffer", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                    console.logError(parsedError, "VehicleOfferRouteComponent", "onConfirmOfferClick");
                                    this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
                                })
                                .catch(jsonParseError => {
                                    console.logError(jsonParseError, "VehicleOfferRouteComponent", "onConfirmOfferClick");
                                });
                        }
                    });
            }
            else {
                this.setState({ showSpinner: false });
            }
        }
        catch (error) {
            console.logError(error, "VehicleOfferRouteComponent", "onConfirmOfferClick");
        }

    }

    toggleTermsNConditions() {
        this.setState(prevState => ({
            showTerms: !prevState.showTerms
        }));
    }

    onThinkingAbtBtnClick() {
        let Url = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Thinking About It Action Url")?.Value ?? "";
        window.open(Url, '_blank');
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let offerStatus = "";
        if (this.state.offerSession) {
            offerStatus = this.state.offerSession.offer.status;
            let expirationDate = new Date(this.state.offerSession.offer.expirationDate)
            let now = new Date();
            now.setHours(0, 0, 0, 0);
            if (expirationDate < now) {
                offerStatus = "Expired";
            }
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Body Html")?.Value ?? "";
        let offerAmount = "";
        if (this.state.offerSession?.offer) {
            offerAmount = "$" + CommonService.formatMoney(this.state.offerSession?.offer.offer);
        }
        bodyHtml = bodyHtml.replace("{{OfferAmount}}", offerAmount);

        return <div className="ac-site ac-vehicleoffer">
            <SellToWebModalDialogComponent
                showDialog={this.state.showDialog}
                className="consumer-vehicleoffer-dialog"
                title="Need More Time?"
                onOkClick={this.onDismissModalClick}>
                <p style={{ fontSize: "13px" }}>
                    <b>Offer Expiration Date:</b>&nbsp;
                    {CommonService.formatDate(this.state.offerSession?.offer.expirationDate)}
                </p>
                <span dangerouslySetInnerHTML={{ __html: CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Thinking About It Html")?.Value ?? "" }} />
                <div className="text-center">
                    <SellToWebButtonComponent onClick={this.onThinkingAbtBtnClick} value={CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Offer", "Default", "Thinking About It Action Text").Value ?? ""} />
                </div>
            </SellToWebModalDialogComponent>
            <header className="ac-site-header ac-vehicleoffer-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={6} />
            <SellToWebTopPanelComponent imageUrl={this.state.offerSession?.vehicleDetail.imageUrl} makePlusModel={this.state.offerSession?.vehicleDetail.year + " " + this.state.offerSession?.vehicleDetail.make + " " + this.state.offerSession?.vehicleDetail.model} trim={this.state.offerSession?.vehicleDetail.trim} />
            <section className="consumer-page">
                {
                    this.state.offerSession
                        ? <Fragment>
                            <section className="consumer-main">
                                {
                                    offerStatus === "Expired"
                                        ? <Fragment>
                                            <h2 className="text-center fw-bold consumer-vehicleoffer-text">Your offer has expired</h2>
                                            <h1 className="text-center fw-bold" style={{ fontSize: "55px" }}>${CommonService.formatMoney(this.state.offerSession?.offer.offer)}</h1>
                                            <p className="ac-vehicleoffer-date" style={{ fontSize: "13px" }}>Expired on <span>{CommonService.formatDate(this.state.offerSession?.offer.expirationDate)}</span></p>
                                            <div className="ac-site-button" style={{ "textAlign": "center" }}>
                                                <a href="/" className="d-block fw-bold">Start New Offer</a>
                                            </div>
                                            <p className="ac-vehicleoffer-date" style={{ fontSize: "13px" }}>
                                                <strong>Offer Code: </strong>
                                                <span style={{ fontWeight: "500" }}>{this.state.offerSession?.header.offerNumber}</span>
                                            </p>
                                        </Fragment>
                                        : <Fragment>
                                            <section className="ac-site-body ac-vehicleoffer-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                                            <section className="ac-vehicleoffer-form">
                                                <table width="100%">
                                                    <tbody>
                                                        {
                                                            this.state.offerSession?.offer.kbbMileageAdjustmentValue > 0 || this.state.offerSession?.offer.kbbOptionAdjustmentValue > 0
                                                                ? <tr>
                                                                    <td className="fw-normal consumer-vehicleoffer-feature-title" style={{ fontSize: "18px" }}>Your offer includes the following bonuses:</td>
                                                                </tr>
                                                                : null
                                                        }
                                                        {
                                                            this.state.offerSession?.offer.kbbMileageAdjustmentValue > 0
                                                                ? <tr>
                                                                    <td className="consumer-vehicleoffer-feature-text">
                                                                        <div className="fw-bold" style={{ fontSize: "20px" }}>Low Miles</div>
                                                                    </td>
                                                                    <td className="consumer-vehicleoffer-feature-value">
                                                                        <div className="text-end fw-bold" style={{ fontSize: "20px" }}>${CommonService.formatMoney(this.state.offerSession?.offer.kbbMileageAdjustmentValue)}</div>
                                                                    </td>
                                                                </tr>
                                                                : null
                                                        }
                                                        {
                                                            this.state.offerSession?.offer.kbbOptionAdjustmentValue > 0
                                                                ? <tr>
                                                                    <td className="consumer-vehicleoffer-feature-text">
                                                                        <div className="fw-bold" style={{ fontSize: "20px" }}>Extra Features</div>
                                                                    </td>
                                                                    <td className="consumer-vehicleoffer-feature-value">
                                                                        <div className="text-end fw-bold" style={{ fontSize: "20px" }}>${CommonService.formatMoney(this.state.offerSession?.offer.kbbOptionAdjustmentValue)}</div>
                                                                    </td>
                                                                </tr>
                                                                : null
                                                        }
                                                        <tr>
                                                            <td>
                                                                <div className="fw-bold consumer-vehicleoffer-amount" style={{ fontSize: "20px" }}>Offer</div>
                                                            </td>
                                                            <td>
                                                                <div className="text-end fw-bold consumer-vehicleoffer-amount ac-site-offer-value" style={{ fontSize: "20px" }}>${CommonService.formatMoney(this.state.offerSession?.offer.offer)}</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="d-flex justify-content-between consumer-vehicleoffer-button cust-vehicleoffer-btn-order">
                                                    <SellToWebButtonComponent value="I'm Ready to Sell" onClick={this.onConfirmOfferClick} showSpinner={this.state.showSpinner} />
                                                    <SellToWebButtonComponent value="I'm Thinking About It" onClick={this.onThinkingAbtClick} />
                                                </div>
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <div style={{ "paddingLeft": "16px", "paddingBottom": "16px", "cursor": "pointer", "width": "50%" }} onClick={this.toggleTermsNConditions}>
                                                        <span className="fw-bold" style={{ fontSize: "13px" }}><u>*Terms & Conditions</u></span>&nbsp;&nbsp;
                                                        <i className="bi bi-chevron-down" style={{ fontSize: "13px" }}></i>
                                                    </div>
                                                    <div style={{ "paddingLeft": "16px", "paddingBottom": "16px", "width": "50%" }}>
                                                        <div className="text-end" style={{ fontSize: "13px" }}>
                                                            <b>Offer Expiration Date:</b>&nbsp;
                                                            <span>{CommonService.formatDate(this.state.offerSession?.offer.expirationDate)}</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.showTerms
                                                            ? <div style={{ "padding": "10px 16px", "width": "100%" }}>
                                                                <p style={{ fontSize: "12px", fontWeight: "500" }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Terms & Conditions Html")?.Value ?? "" }} />
                                                                </p>
                                                            </div>
                                                            : null
                                                    }
                                                    <div style={{ "padding": "10px 16px", "width": "100%" }}>
                                                        <p style={{ fontSize: "13px", color: "#949494" }}>
                                                            <strong>Offer Code: </strong>
                                                            <span style={{ fontWeight: "500" }}>{this.state.offerSession?.offer.offerNumber}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </section>
                                        </Fragment>
                                }
                            </section>
                            <aside className="consumer-summary">
                                <SellToWebRightPanelComponent
                                    vin={this.state.offerSession?.vehicleDetail.vin}
                                    makePlusModel={this.state.offerSession?.vehicleDetail.year + " " + this.state.offerSession?.vehicleDetail.make + " " + this.state.offerSession?.vehicleDetail.model}
                                    imageUrl={this.state.offerSession?.vehicleDetail.imageUrl}
                                    odometer={CommonService.formatMoney(this.state.offerSession?.vehicleDetail.odometer)}
                                    engine={this.state.offerSession?.vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Engine")).map((arrayData => arrayData.optionName))}
                                    transmission={this.state.offerSession?.vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Transmission")).map((arrayData => arrayData.optionName))}
                                    driveTrain={this.state.offerSession?.vehicleDetail.vehicleOptions?.filter((arrayData => arrayData.categoryGroup === "Drivetrain")).map((arrayData => arrayData.optionName))}
                                    colorBox={this.state.offerSession?.vehicleDetail.colorRgb1 ? "#" + this.state.offerSession?.vehicleDetail.colorRgb1 : CommonService.checkCSSColorSupports(this.state.offerSession?.vehicleDetail.colorSimpleTitle)}
                                    colorName={(this.state.offerSession?.vehicleDetail.colorTitle && this.state.offerSession?.vehicleDetail.colorTitle !== "") ? this.state.offerSession?.vehicleDetail.colorTitle : this.state.offerSession?.vehicleDetail.colorSimpleTitle}
                                    trim={this.state.offerSession?.vehicleDetail.trim}
                                    minimumCondition={CommonService.getMinimumSelectedCondition(this.state.offerSession?.vehicleCondition.paintBody, this.state.offerSession?.vehicleCondition.windowsLights, this.state.offerSession?.vehicleCondition.wheelsTires, this.state.offerSession?.vehicleCondition.upholstery, this.state.offerSession?.vehicleCondition.interiorEquipment, this.state.offerSession?.vehicleCondition.mechanical)}
                                    siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                                />
                            </aside>
                        </Fragment>
                        : null
                }
            </section>
            <footer className="ac-site-footer ac-vehicleoffer-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}