import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleHistoryRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetailsData: null,
            redirect: false
        };

        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onVehicleConditionClick = this.onVehicleConditionClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    pageTitle = "Vehicle History";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-history", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, vehicleId: vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            CommonService.clientAzureStorageLog("VehicleHistoryRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId ?? vehicleDetailsData?.selectedTrim?.description?.trim + " " + vehicleDetailsData?.vin, null, null, null, this.props.applicationInsights);
            this.setState({ vehicleDetailsData: vehicleDetailsData });
        }
        catch (error) {
            console.logError(error, "VehicleHistoryRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId ?? vehicleDetailsData?.selectedTrim?.description?.trim + " " + vehicleDetailsData?.vin);
        }
    }

    onVehicleConditionClick() {
        try {
            this.setState({ redirect: true });
        }
        catch (error) {
            console.logError(error, "VehicleHistoryRouteComponent", "onVehicleConditionClick");
        }
    }

    onBackButtonClick() {
        try {
            window.history.go(-1);
        }
        catch (error) {
            console.logError(error, "VehicleHistoryRouteComponent", "onBackButtonClick");
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.onVehicleConditionClick();
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={"/vehicle-condition"} />;
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle History", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle History", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle History", "Default", "Body Html")?.Value ?? "";

        let accidentDetails = this.state.vehicleDetailsData ? this.state.vehicleDetailsData.autocheckData.accidentDetails : [];
        let accidentDetailsElement = [];
        accidentDetails.forEach((accidentDetail, index) => {
            accidentDetailsElement.push(<div style={{ border: "1px solid #dadada", marginBottom: "10px", alignItems: "center", display: "flex" }}>
                <div style={{ fontSize: "20px", backgroundColor: "rgba(0, 130, 236, 0.04)", borderRight: "1px solid #DCDCDC", padding: "15px 20px" }}>{index + 1}</div>
                <div style={{ fontSize: "12px", fontWeight: "500", paddingLeft: "20px", width: "130px" }} className="ac-vehiclehistory-accident-date">{moment(accidentDetail.date).format("MMMM DD, YYYY")}</div>
                <div style={{ fontSize: "15px", paddingLeft: "20px" }}>{accidentDetail.type}</div>
            </div>);
        });

        return <div className="ac-site ac-vehiclehistory">
            <header className="ac-site-header ac-vehiclehistory-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={2} />
            <SellToWebTopPanelComponent imageUrl={this.state.vehicleDetailsData?.imageUrl} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} trim={this.state.vehicleDetailsData?.selectedTrim ? (this.state.vehicleDetailsData.selectedTrim.trimName ?? this.state.vehicleDetailsData.selectedTrim.description.trim) : "--"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehiclehistory-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="ac-vehiclehistory-form" onKeyDown={(event) => this.onKeyDown(event)} tabIndex={-1}>
                        <figure style={{ textAlign: "center" }}>
                            <img src="images/autocheck.png" alt="autocheck" />
                        </figure>
                        {accidentDetailsElement}
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onVehicleConditionClick} value="Continue" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={this.state.vehicleDetailsData?.vin} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} valuationMethod={this.props.valuationMethod}
                        imageUrl={this.state.vehicleDetailsData?.imageUrl} differentColorShown={this.state.vehicleDetailsData?.differentColorShown} odometer={CommonService.formatMoney(this.state.vehicleDetailsData?.odometer)}
                        engine={this.state.vehicleDetailsData?.selectedEngine ? this.state.vehicleDetailsData.selectedEngine.optionName : "--"}
                        transmission={this.state.vehicleDetailsData?.selectedTransmission ? this.state.vehicleDetailsData.selectedTransmission.optionName : "--"}
                        driveTrain={this.state.vehicleDetailsData?.selectedDriveTrain ? this.state.vehicleDetailsData.selectedDriveTrain.optionName : "--"}
                        colorBox={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedVehicleOEMColor.colorBox) : null}
                        colorName={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.title ? this.state.vehicleDetailsData?.selectedVehicleOEMColor.title : this.state.vehicleDetailsData?.selectedVehicleOEMColor?.optionName}
                        trim={this.state.vehicleDetailsData?.selectedTrim ? (this.state.vehicleDetailsData.selectedTrim.trimName ?? this.state.vehicleDetailsData.selectedTrim.description.trim) : "--"}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
            <footer className="ac-site-footer ac-vehiclehistory-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}