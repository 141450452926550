import React, { Component } from "react";
import $ from "jquery";
import { Navigate } from "react-router-dom";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebProgressbarComponent } from "../common/selltowebprogressbar/SelltoWebProgressbarComponent";
import { SellToWebTopPanelComponent } from "../common/selltowebtoppanel/SellToWebTopPanelComponent";
import { SellToWebRightPanelComponent } from "../common/selltowebrightpanel/SellToWebRightPanelComponent";
import { SellToWebCheckboxComponent } from "../common/selltowebcheckbox/SellToWebCheckboxComponent";
import { SellToWebButtonComponent } from "../common/selltowebbutton/SellToWebButtonComponent";

export class VehicleEquipmentRouteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vehicleDetailsData: null,
            optionList: [],
            showRefreshSpinner: false,
            redirectTo: "",
            showSpinner: false,
            disableBackground: false,
            selectedOptionIdsList: []
        };

        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onVehicleOptionChange = this.onVehicleOptionChange.bind(this);
        this.onVehicleConditionClick = this.onVehicleConditionClick.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    pageTitle = "Vehicle Equipment";

    componentDidMount() {
        let vehicleDetailsData = CommonService.getSessionStorage("vehicleDetailsData");
        let selectedOptionIdsList = CommonService.getLocalStorage("VehicleEquipment" + vehicleDetailsData.selectedTrim.vehicleId + "SelectedOptions");
        try {
            this.props.onPageLoad({ pageTitle: this.pageTitle });
            this.props.applicationInsights.trackPageView({ name: this.pageTitle, url: "/vehicle-equipment", pageType: "Consumer", isLoggedIn: "No", properties: { vin: vehicleDetailsData?.vin, vehicleId: vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            if (!vehicleDetailsData) {
                CommonService.clientAzureStorageLog("VehicleEquipmentRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId + " " + vehicleDetailsData?.vin, null, null, null, this.props.applicationInsights);
                this.setState({ showSpinner: false, redirectTo: "/unique-vehicle" });
            }
            else {
                selectedOptionIdsList?.forEach(optionId => {
                    let selectedOption = vehicleDetailsData.selectedTrim.vehicleOptions.find(vehicleOption => vehicleOption.vehicleOptionId === optionId);
                    selectedOption.isChecked = true;
                });

                this.setState({ vehicleDetailsData: vehicleDetailsData, selectedOptionIdsList: selectedOptionIdsList });
            }
        }
        catch (error) {
            console.logError(error, "VehicleEquipmentRouteComponent", "componentDidMount", vehicleDetailsData?.selectedTrim?.vehicleId + " " + vehicleDetailsData?.vin);
        }
    }

    populateOptions() {
        try {
            let selectedTrim = this.state.vehicleDetailsData?.selectedTrim;
            let optionList = [];
            let options = [];
            let categoryOptions = [];
            let currentCategory = "";
            if (selectedTrim) {
                options = selectedTrim.vehicleOptions.filter(option => !option.isTypical);
                options.sort((row1, row2) => { return row1.sortOrder - row2.sortOrder }).forEach((vehicleOption, index) => {
                    let category = vehicleOption.categoryGroup;
                    if (currentCategory !== category || options.length === (index + 1)) {
                        if (categoryOptions.length > 0) {
                            optionList.push(<div key={"OT-T-" + index.toString()}>
                                <h4 style={{ "fontWeight": "bold", "fontSize": "16px", "marginBottom": "16px" }}>{currentCategory}</h4>
                                {categoryOptions}
                                <hr className="consumer-vehiclecondition-seperator" />
                            </div>);
                        }

                        currentCategory = category;
                        categoryOptions = [];
                    }

                    switch (vehicleOption.categoryGroup) {
                        case "Engine":
                        case "Transmission":
                        case "Drivetrain":
                        case "Exterior Color":
                            break;
                        default:
                            let label = vehicleOption.optionName;
                            let alertElementId = "otWarning" + index.toString();
                            if (!vehicleOption.isTypical) {
                                categoryOptions.push(<SellToWebCheckboxComponent key={"OT-" + index.toString()} label={label} alertId={alertElementId} additionalData={vehicleOption} showSpinner={this.state.showRefreshSpinner} onChange={this.onVehicleOptionChange} value={vehicleOption.isChecked === undefined ? vehicleOption.isTypical : vehicleOption.isChecked} />);
                            }

                            if (vehicleOption.showWarning) {
                                setTimeout((alertElementId) => {
                                    vehicleOption.showWarning = false;
                                    vehicleOption.parentVehicleOption = null;
                                    let alertElement = $(alertElementId);
                                    if (alertElement.length > 0) {
                                        alertElement.hide("fast");
                                        alertElement = $(alertElementId);
                                    }
                                }, 4000, "#" + alertElementId);
                            }

                            break;
                    }
                });
            }

            return optionList;
        }
        catch (error) {
            console.logError(error, "VehicleEquipmentRouteComponent", "populateOptions", this.state.vehicleDetailsData?.selectedTrim?.vehicleId + " " + this.state.vehicleDetailsData?.vin);
        }
    }

    validateVehicleOptions(vehicleOption) {
        try {
            let vehicleOptionIds = [];
            if (this.state.vehicleDetailsData.selectedTrim.vehicleOptions && this.state.vehicleDetailsData.selectedTrim.vehicleOptions.length > 0) {
                this.state.vehicleDetailsData.selectedTrim.vehicleOptions.forEach(vehicleOption => {
                    if (vehicleOption.isChecked || vehicleOption.isTypical === true) {
                        vehicleOptionIds.push(vehicleOption.vehicleOptionId);
                    }
                })
            }
            vehicleOptionIds.push(this.state.vehicleDetailsData.selectedEngine.vehicleOptionId);
            vehicleOptionIds.push(this.state.vehicleDetailsData.selectedTransmission.vehicleOptionId);
            vehicleOptionIds.push(this.state.vehicleDetailsData.selectedDriveTrain.vehicleOptionId);
            if (this.state.vehicleDetailsData.selectedExteriorColor) {
                vehicleOptionIds.push(this.state.vehicleDetailsData.selectedExteriorColor.vehicleOptionId);
            }
            let inputData = {
                vin: this.state.vehicleDetailsData?.vin,
                vehicleId: this.state.vehicleDetailsData?.selectedTrim?.vehicleOptions[0]?.vehicleId,
                vehicleOptionId: vehicleOption?.vehicleOptionId,
                vehicleOptionName: vehicleOption?.optionName,
                vehicleOptionIds: vehicleOptionIds
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleEquipmentRouteComponent/validateVehicleOptions", properties: { ...inputData, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleEquipment/ValidateConfiguration", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errors) {
                        console.logError(parsedResponse.errors, "VehicleEquipmentRouteComponent", "validateVehicleOptions");
                    }
                    else {
                        let selectedOptionIdsList = this.state.selectedOptionIdsList;
                        let vehicleOptions = this.state.vehicleDetailsData.selectedTrim.vehicleOptions;
                        let validateReponse = parsedResponse;
                        if (!validateReponse.isValid) {
                            validateReponse.warnings.forEach((warning) => {
                                switch (warning.code) {
                                    case "Configuration.SelectOneChildId":
                                        if (warning.parentId === vehicleOption.vehicleOptionId) {
                                            let childSelected = false;
                                            warning.childIds.forEach(childId => {
                                                let childVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                if (childVehicleOption.isChecked) {
                                                    if (!childSelected) {
                                                        childSelected = true;
                                                    }
                                                }
                                            });
                                            if (!childSelected) {
                                                vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.childIds[0])[0].isChecked = true;
                                            }
                                        }
                                        else {
                                            if (vehicleOption.vehicleOptionId === warning.childIds[0]) {
                                                let parentVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                parentVehicleOption.isChecked = false;
                                                parentVehicleOption.showWarning = true;
                                                parentVehicleOption.message = "Not supported with " + vehicleOption.optionName;
                                                parentVehicleOption.parentVehicleOption = [];
                                            }
                                        }

                                        break;

                                    case "Configuration.DeselectAllChildIds":
                                        if (warning.parentId === vehicleOption.vehicleOptionId) {
                                            warning.childIds.forEach(childId => {
                                                let deselectVehicleOption = null;
                                                let conflictWithVehicleOption = null
                                                if (childId === this.state.vehicleDetailsData.selectedEngine.vehicleOptionId
                                                    || childId === this.state.vehicleDetailsData.selectedTransmission.vehicleOptionId
                                                    || childId === this.state.vehicleDetailsData.selectedDriveTrain.vehicleOptionId) {
                                                    deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                    conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                }
                                                else {
                                                    deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];
                                                    conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                }

                                                if (deselectVehicleOption && deselectVehicleOption.isChecked) {
                                                    deselectVehicleOption.isChecked = false;
                                                    deselectVehicleOption.showWarning = true;
                                                    deselectVehicleOption.message = "Not supported with " + conflictWithVehicleOption.optionName;
                                                    selectedOptionIdsList = selectedOptionIdsList.filter(optionId => optionId !== deselectVehicleOption.vehicleOptionId);
                                                }
                                            });
                                        }
                                        else {
                                            warning.childIds.forEach(childId => {
                                                if (childId === this.state.vehicleDetailsData.selectedEngine.vehicleOptionId
                                                    || childId === this.state.vehicleDetailsData.selectedTransmission.vehicleOptionId
                                                    || childId === this.state.vehicleDetailsData.selectedDriveTrain.vehicleOptionId) {
                                                    let deselectVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === warning.parentId)[0];
                                                    let conflictWithVehicleOption = vehicleOptions.filter(vehicleOption => vehicleOption.vehicleOptionId === childId)[0];

                                                    if (deselectVehicleOption && deselectVehicleOption.isChecked) {
                                                        deselectVehicleOption.isChecked = false;
                                                        deselectVehicleOption.showWarning = true;
                                                        deselectVehicleOption.message = "Not supported with " + conflictWithVehicleOption.optionName;
                                                        selectedOptionIdsList = selectedOptionIdsList.filter(optionId => optionId !== deselectVehicleOption.vehicleOptionId);
                                                    }
                                                }
                                            });
                                        }
                                        break;

                                    default:
                                        console.logError(warning.code, "VehicleEquipmentRouteComponent", "validateVehicleOptions");
                                        break;
                                }
                            });
                        }

                        let vehicleDetailsData = this.state.vehicleDetailsData;
                        vehicleDetailsData.selectedTrim.vehicleOptions.filter(option => option.categoryGroup === vehicleOption.categoryGroup && option.optionName === vehicleOption.optionName)[0].showSpinner = false;
                        this.setState({
                            selectedOptionIdsList: selectedOptionIdsList,
                            vehicleDetailsData: vehicleDetailsData,
                            disableBackground: false
                        });
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleEquipment/ValidateConfiguration", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleEquipmentRouteComponent", "validateVehicleOptions");
                                this.props.onShowAlert("danger", parsedError);
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleEquipmentRouteComponent", "validateVehicleOptions");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleEquipmentRouteComponent", "validateVehicleOptions");
        }
    }

    onBackButtonClick() {
        window.history.go(-1);
    }

    onVehicleOptionChange(id, vehicleOption) {
        try {
            let vehicleDetailsData = this.state.vehicleDetailsData;
            let rowData = vehicleDetailsData.selectedTrim.vehicleOptions.filter(option => option.categoryGroup === vehicleOption.categoryGroup && option.optionName === vehicleOption.optionName)[0];
            rowData.isChecked = !rowData.isChecked;
            rowData.showSpinner = true;
            let selectedOptionIds = this.state.selectedOptionIdsList ?? [];
            if (!selectedOptionIds?.find(optionId => optionId === rowData.vehicleOptionId)) {
                selectedOptionIds.push(rowData.vehicleOptionId);
            }
            else {
                selectedOptionIds = selectedOptionIds.filter(optionId => optionId !== rowData.vehicleOptionId);
            }
            this.setState({
                selectedOptionIdsList: selectedOptionIds,
                vehicleDetailsData: vehicleDetailsData,
                disableBackground: true
            }, () => {
                this.validateVehicleOptions(vehicleOption);
            });
        }
        catch (error) {
            console.logError(error, "VehicleEquipmentRouteComponent", "onVehicleOptionChange", id);
        }
    }

    onVehicleConditionClick() {
        try {
            this.setState({ showSpinner: true });
            let inputData = {
                Vin: this.state.vehicleDetailsData.vin
            };
            this.props.applicationInsights.trackTrace({ message: "VehicleEquipmentRouteComponent/onVehicleConditionClick", properties: { ...inputData, vehicleId: this.state.vehicleDetailsData?.selectedTrim?.vehicleId, tenantName: this.props.tenantName, siteName: this.props.siteName } });
            fetch("/VehicleEquipment/GetAutoCheckData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8;"
                },
                body: JSON.stringify(inputData),
                credentials: "same-origin"
            })
                .then(response => { if (response.ok) { return response.json() } else { throw response; } })
                .then(parsedResponse => {
                    if (parsedResponse.errors || parsedResponse.status === "Error") {
                        CommonService.clearLocalStorage(this.state.vehicleDetailsData?.vin, this.state.vehicleDetailsData?.selectedTrim?.vehicleId);
                        this.setState({
                            redirectTo: "/unique-vehicle",
                            showSpinner: false
                        });
                    }
                    else {
                        let vehicleDetailsData = this.state.vehicleDetailsData;
                        let autocheckData = {
                            autocheckResponse: parsedResponse.autocheckResponse,
                            accidentCount: parsedResponse.accidentCount,
                            ownerCount: parsedResponse.ownerCount,
                            accidentDetails: parsedResponse.accidentDetails,
                            useAutocheck: parsedResponse.useAutocheck
                        }
                        vehicleDetailsData["autocheckData"] = autocheckData;
                        CommonService.setSessionStorage("vehicleDetailsData", vehicleDetailsData);
                        let offerSession = CommonService.getSessionStorage("offerSession");
                        offerSession.vehicleHistory = {};
                        offerSession.vehicleHistory.owners = autocheckData.ownerCount;
                        offerSession.vehicleHistory.accidentCount = autocheckData.accidentCount;
                        offerSession.vehicleHistory.autocheckResponse = autocheckData.autocheckResponse;
                        offerSession.vehicleHistory.useAutocheck = autocheckData.useAutocheck;
                        CommonService.setSessionStorage("offerSession", offerSession);
                        CommonService.setLocalStorage("VehicleEquipment" + vehicleDetailsData.selectedTrim.vehicleId + "SelectedOptions", this.state.selectedOptionIdsList);
                        if (parsedResponse.accidentCount.toString() === "0") {
                            this.setState({ redirectTo: "/vehicle-condition" });
                        }
                        else {
                            this.setState({ redirectTo: "/vehicle-history" });
                        }
                    }
                })
                .catch(notOKResponse => {
                    if (notOKResponse.status === 500) {
                        notOKResponse.json()
                            .then(parsedError => {
                                this.props.applicationInsights.trackException({ exception: parsedError, properties: { method: "/VehicleEquipment/GetAutoCheckData", ...inputData, ...parsedError, tenantName: this.props.tenantName, siteName: this.props.siteName } });
                                console.logError(parsedError, "VehicleEquipmentRouteComponent", "onVehicleConditionClick");
                            })
                            .catch(jsonParseError => {
                                console.logError(jsonParseError, "VehicleEquipmentRouteComponent", "onVehicleConditionClick");
                            });
                    }
                });
        }
        catch (error) {
            console.logError(error, "VehicleEquipmentRouteComponent", "onVehicleConditionClick");
        }
    }

    onKeyDown(event) {
        if (event.keyCode === 13) {
            this.onVehicleConditionClick();
        }
    }

    render() {
        if (this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} />;
        }

        let headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Equipment", "Default", "Header Html")?.Value;
        if (!headerHtml) {
            headerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Header Html")?.Value ?? "";
        }

        let footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Equipment", "Default", "Footer Html")?.Value;
        if (!footerHtml) {
            footerHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Site", "Default", "Footer Html")?.Value ?? "";
        }

        let bodyHtml = CommonService.getSiteAttributeValue(this.props.siteAttributes, "Vehicle Equipment", "Default", "Body Html")?.Value ?? "";

        return <div className="ac-site ac-vehicleequipment">
            <header className="ac-site-header ac-vehicleequipment-header" dangerouslySetInnerHTML={{ __html: headerHtml }} />
            <SellToWebProgressbarComponent progressIndex={2} />
            <SellToWebTopPanelComponent imageUrl={this.state.vehicleDetailsData?.imageUrl} makePlusModel={this.state.vehicleDetailsData?.makePlusModel} trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.trimName : "--"} />
            <section className="consumer-page">
                <section className="consumer-main">
                    <section className="ac-site-body ac-vehicleequipment-body" dangerouslySetInnerHTML={{ __html: bodyHtml }} />
                    <section className="ac-vehicleequipment-form" onKeyDown={(event) => this.onKeyDown(event)} tabIndex={-1}>
                        {this.populateOptions()}
                        <div className="d-flex justify-content-end">
                            <div style={{ "cursor": "pointer", "fontSize": "12px", "fontWeight": "400", "textDecoration": "underline", "margin": "auto 30px" }} onClick={(event => { window.history.go(-1); })}>
                                <span className="bi bi-chevron-left" style={{ "marginRight": "5px", "verticalAlign": "middle" }} />
                                Go Back
                            </div>
                            <SellToWebButtonComponent onClick={this.onVehicleConditionClick} showSpinner={this.state.showSpinner} value="Vehicle Condition" />
                        </div>
                    </section>
                </section>
                <aside className="consumer-summary">
                    <SellToWebRightPanelComponent
                        vin={this.state.vehicleDetailsData?.vin} makePlusModel={this.state.vehicleDetailsData?.makePlusModel}
                        imageUrl={this.state.vehicleDetailsData?.imageUrl} differentColorShown={this.state.vehicleDetailsData?.differentColorShown} odometer={CommonService.formatMoney(this.state.vehicleDetailsData?.odometer)}
                        engine={this.state.vehicleDetailsData?.selectedEngine ? this.state.vehicleDetailsData.selectedEngine.optionName : "--"}
                        transmission={this.state.vehicleDetailsData?.selectedTransmission ? this.state.vehicleDetailsData.selectedTransmission.optionName : "--"}
                        driveTrain={this.state.vehicleDetailsData?.selectedDriveTrain ? this.state.vehicleDetailsData.selectedDriveTrain.optionName : "--"}
                        colorBox={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.colorBox ? CommonService.checkCSSColorSupports(this.state.vehicleDetailsData?.selectedVehicleOEMColor.colorBox) : null}
                        colorName={this.state.vehicleDetailsData?.selectedVehicleOEMColor?.title ? this.state.vehicleDetailsData?.selectedVehicleOEMColor.title : this.state.vehicleDetailsData?.selectedVehicleOEMColor?.optionName}
                        trim={this.state.vehicleDetailsData?.selectedTrim ? this.state.vehicleDetailsData.selectedTrim.trimName : "--"}
                        siteAttributes={this.props.siteAttributes} applicationInsights={this.state.applicationInsights}
                    />
                </aside>
            </section>
            <footer className="ac-site-footer ac-vehicleequipment-footer" dangerouslySetInnerHTML={{ __html: footerHtml }} />
        </div>;
    }
}