import React from "react";
import $ from "jquery";

export class SellToWebAdminFormComboBoxComponent extends React.Component {
    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    onItemClick(id, item) {
        if (this.props.onItemClick) {
            $(this.inputElement).focus(); //focus current textbox
            this.props.onItemClick(this.props.id, item);
        }
    }

    onTextChange(event) {
        this.combobox.classList.add("show");
        const dropdownMenu = this.combobox.querySelector(".dropdown-menu");
        if (dropdownMenu) {
            dropdownMenu.classList.add("show");
        }
        if (this.props.onItemChange) {
            this.props.onItemChange(this.props.id, event.target.value);
        }
    }

    onKeyDown(event) {
        if (this.props.enterToTab && event.keyCode === 13) {
            event.preventDefault();
            const inputs = Array.from(document.querySelectorAll("input")); //Get array of inputs
            let nextInput = inputs[inputs.indexOf(document.activeElement) + 1];//get index of input after the current input
            nextInput.focus(); //focus new input
        }
        else if (this.props.onLookupClick && event.keyCode === 13) {
            this.props.onLookupClick(this.props.id, this.props.value, this.props.additionalData);
        }
        else if (this.props.onKeyDown) {
            this.props.onKeyDown(event, this.props.additionalData);
        }
    }

    onFocus(event) {
        if (this.props.onItemChange && (this.props.value && event.target.type === "text")) {
            let value = this.props.value ? this.props.value : event.target.innerText;
            this.props.onItemChange(this.props.id, value);
            $(this.inputElement).focus();
        }
    }

    render() {
        let colSpan = this.props.colSpan ? this.props.colSpan : "col-form-label";
        let labelSpan = this.props.labelSpan ? this.props.labelSpan : "col-form-label";
        let controlSpan = this.props.controlSpan ? this.props.controlSpan : "col-form-label";
        if (this.props.cssName) {
            colSpan += " " + this.props.cssName;
        }
        if (this.props.controlCssName) {
            controlSpan += " " + this.props.controlCssName;
        }

        let ddlElements = [];
        if (this.props.data) {
            this.props.data.forEach((item, index) => {
                ddlElements.push(<button key={"ddlItem" + index} type="button" className="btn btn-link dropdown-item" onClick={(event) => this.onItemClick(event, item)}>
                    {
                        this.props.itemKey === null
                            ? item
                            : item[this.props.itemKey]
                    }
                </button>);
            });
        }

        return <div className={"form-group " + colSpan} onFocus={(event) => this.onFocus(event)}>
            {
                this.props.label
                    ? <label className={"admin-label " + labelSpan}>
                        {this.props.label}:
                        {
                            this.props.isRequired
                                ? <span className="text-danger">*</span>
                                : null
                        }
                    </label>
                    : null
            }

            <div className={controlSpan}>
                <div ref={refElement => this.combobox = refElement} className="dropdown admin-dropdown">
                    <div className="input-group" data-bs-toggle={this.props.disabled ? "" : "dropdown"} aria-haspopup="true" aria-expanded="false">
                        <input ref={refElement => this.inputElement = refElement} id={this.props.id} className="form-control" disabled={this.props.disabled} placeholder={this.props.placeHolder} value={this.props.value ? this.props.value : ""} autoComplete="off"
                            onChange={(event) => this.onTextChange(event)} onKeyDown={(event) => this.onKeyDown(event)} />
                        <button className="btn btn-secondary dropdown-toggle" type="button" style={{ "zIndex": "100" }} tabIndex="-1" />
                    </div>
                    <div className="dropdown-menu sell-to-admin-dropdown-menu">
                        {ddlElements}
                    </div>
                </div>
            </div>
        </div>;
    }
}
