import React, { Fragment } from "react";
import moment from "moment";

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminModalDialogComponent } from "../common/selltowebmodaldialog/SellToWebAdminModalDialogComponent";
import { SellToWebAdminCheckboxComponent } from "../common/selltowebcheckbox/SellToWebAdminCheckboxComponent";
import { SellToWebAdminTextAreaComponent } from "../common/selltowebtextarea/SellToWebAdminTextAreaComponent";

export class EVOXImagesComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imageType: "Front",
            selectedVehicleOEMColors: null,
            isImageAvailable: {
                selectedImage: true,
                thumbnailFront: true,
                thumbnailSide: true,
                thumbnailRear: true
            },
            isDefaultImageAvailable: {
                selectedImage: true,
                thumbnailFront: true,
                thumbnailSide: true,
                thumbnailRear: true
            },
            showRawViewPopup: false,
            rawViewTitle: "",
            rawViewData: null,
            isDebug: false,
            childModelClosedTime: moment()
        };

        this.onVehicleOEMColorsClick = this.onVehicleOEMColorsClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onThumbnailClick = this.onThumbnailClick.bind(this);
        this.onMissingImage = this.onMissingImage.bind(this);
        this.onDebugChange = this.onDebugChange.bind(this);
        this.onRawViewClick = this.onRawViewClick.bind(this);
        this.onCancelRawViewClick = this.onCancelRawViewClick.bind(this);

        //console.devLog("State: " + CommonService.getComponentConstructorData(this.state));
        //console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedVehicleOEMColors === null && this.props.vehicleOEMColors) {
            this.setState({
                selectedVehicleOEMColors: this.props.vehicleOEMColors[0]
            });
        }
    }

    onVehicleOEMColorsClick(event, selectedValue) {
        try {
            this.setState({
                selectedVehicleOEMColors: selectedValue,
                isImageAvailable: {
                    selectedImage: true,
                    thumbnailFront: true,
                    thumbnailSide: true,
                    thumbnailRear: true
                },
                isDefaultImageAvailable: {
                    selectedImage: true,
                    thumbnailFront: true,
                    thumbnailSide: true,
                    thumbnailRear: true
                }
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onVehicleOEMColorsClick");
        }
    }

    onThumbnailClick(imageType) {
        let isImageAvailable = this.state.isImageAvailable;
        isImageAvailable["selectedImage"] = true;
        let isDefaultImageAvailable = this.state.isDefaultImageAvailable;
        isDefaultImageAvailable["selectedImage"] = true;
        this.setState({
            isImageAvailable: isImageAvailable,
            isDefaultImageAvailable: isDefaultImageAvailable,
            imageType: imageType
        });
    }

    onCancelClick() {
        try {
            this.setState({
                imageType: "Front",
                selectedVehicleOEMColors: null,
                isImageAvailable: {
                    selectedImage: true,
                    thumbnailFront: true,
                    thumbnailSide: true,
                    thumbnailRear: true
                },
                isDefaultImageAvailable: {
                    selectedImage: true,
                    thumbnailFront: true,
                    thumbnailSide: true,
                    thumbnailRear: true
                }
            }, this.props.onCancelClick());
        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onCancelClick");
        }
    }

    onMissingImage(event, isDefault) {
        try {
            if (isDefault) {
                let isDefaultImageAvailable = this.state.isDefaultImageAvailable;
                isDefaultImageAvailable[event.target.id] = false;
                this.setState({
                    isDefaultImageAvailable: isDefaultImageAvailable
                });
            }
            else {
                let isImageAvailable = this.state.isImageAvailable;
                isImageAvailable[event.target.id] = false;
                this.setState({
                    isImageAvailable: isImageAvailable
                });
            }

            CommonService.clientAzureStorageLog("EVOXImagesComponent", this.props.logPart2, "JsError", event.target.src, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onMissingImage");
        }
    }

    onDebugChange(id, isChecked) {
        try {
            this.setState({ [id]: isChecked });
        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onDebugChange");
        }
    }

    onRawViewClick(event) {
        try {
            this.setState({
                showRawViewPopup: true,
                rawViewTitle: this.props.titlePrefix + " OEMColors : " + (this.props.photoRowData && this.props.photoRowData.evoxDisplayName ? this.props.photoRowData.evoxDisplayName : ""),
                rawViewData: JSON.stringify(this.props.vehicleOEMColors, null, "\t")
            });

        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onRawViewClick");
        }
    }

    onCancelRawViewClick(event) {
        try {
            this.setState({
                showRawViewPopup: false,
                rawViewTitle: "",
                rawViewData: null,
                childModelClosedTime: moment()
            });
        }
        catch (error) {
            console.logError(error, "EVOXImagesComponent", "onCancelRawViewClick");
        }
    }

    render() {
        let OEMColors = [];
        let vehicleId = null;
        if (this.props.vehicleOEMColors && !this.props.vehicleOEMColors.status) {
            this.props.vehicleOEMColors.forEach((oemColor) => {
                let rgbColor = "#" + oemColor.rgb1;
                if (this.state.isDebug) {
                    OEMColors.push(<div className={"d-inline-block admin-sell-trade-MMR-evox-oem-color" + (oemColor.default ? " admin-sell-trade-MMR-evox-default-color" : "")} style={{ "backgroundColor": rgbColor }} role="button" onClick={(event) => this.onVehicleOEMColorsClick(event, oemColor)} />);
                }
                else if (!oemColor.duplicate) {
                    OEMColors.push(<div className={"d-inline-block admin-sell-trade-MMR-evox-oem-color" + (oemColor.default ? " admin-sell-trade-MMR-evox-default-color" : "")} style={{ "backgroundColor": rgbColor }} role="button" onClick={(event) => this.onVehicleOEMColorsClick(event, oemColor)} />);
                }
            });
        }

        if (this.props.photoRowData && this.props.photoRowData.hasOwnProperty(this.props.vehicleIdPropertyName)) {
            vehicleId = this.props.photoRowData[this.props.vehicleIdPropertyName]
        }

        return <Fragment>
            <SellToWebAdminModalDialogComponent
                applicationInsights={this.props.applicationInsights}
                showDialog={this.props.photoRowData}
                className="admin-evox-images-MMR-evox-photos-dialog"
                title={this.props.titlePrefix + ": " + (this.props.photoRowData && this.props.photoRowData.evoxDisplayName ? this.props.photoRowData.evoxDisplayName : "")}
                onCancelClick={this.onCancelClick}
                childModelClosedTime={this.state.childModelClosedTime}
            >
                <SellToWebAdminModalDialogComponent
                    applicationInsights={this.props.applicationInsights}
                    title={this.state.rawViewTitle}
                    showDialog={this.state.showRawViewPopup}
                    className="admin-evox-images-oem-colors-raw-view-dialog"
                    onCancelClick={this.onCancelRawViewClick}
                >
                    <div className="admin-body">
                        <div className="card">
                            <div className="card-block">
                                <div className="form">
                                    <SellToWebAdminTextAreaComponent id="oemColorsRawView" value={this.state.rawViewData} rows="25" readOnly />
                                </div>
                            </div>
                        </div>
                    </div>
                </SellToWebAdminModalDialogComponent>
                <div style={{ "minHeight": "480px" }}>
                    {
                        this.state.selectedVehicleOEMColors && (vehicleId !== null)
                            ?
                            <div>
                                <div className="text-center">
                                    <div className="admin-sell-trade-MMR-evox-image">
                                        {
                                            this.state.isImageAvailable["selectedImage"]
                                                ? <img id="selectedImage" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + this.state.imageType.toLowerCase() + this.props.imgUrlSeparator + this.state.selectedVehicleOEMColors.code + this.props.imageExtension} alt={this.props.imageAlt} onError={(event) => this.onMissingImage(event, false)} />
                                                : this.state.isDefaultImageAvailable["selectedImage"]
                                                    ? <img id="selectedImage" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + this.state.imageType.toLowerCase() + this.props.imgUrlSeparator + "default" + this.props.imageExtension} alt={this.props.imageAlt} onError={(event) => this.onMissingImage(event, true)} />
                                                    : <div className="admin-sell-trade-selected-image-water-mark">No Image Available.</div>
                                        }
                                    </div>
                                    <div className="pb-1" style={{ "paddingLeft": "9px" }}>
                                        <div className="d-flex" style={{marginLeft:"-5px", marginRight:"-5px"}}>
                                            <SellToWebAdminCheckboxComponent id="isDebug" label="Debug" value={this.state.isDebug} onChange={this.onDebugChange} />
                                            <label className="admin-sell-trade-MMR-evox-image-label d-flex align-items-center">{this.state.selectedVehicleOEMColors.title}, {this.state.imageType} Profile {(!this.state.isImageAvailable["selectedImage"] && this.state.isDefaultImageAvailable["selectedImage"]) ? "(Default color shown)" : ""}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="admin-sell-trade-MMR-evox-thumbnail">
                                            <div className="admin-sell-trade-MMR-evox-oem-colors">
                                                {OEMColors}
                                            </div>
                                            <div style={{ "width": "230px" }}>
                                                {this.state.selectedVehicleOEMColors.title} ({this.state.selectedVehicleOEMColors.simpletitle})
                                            </div>
                                        </div>
                                        <div className="admin-sell-trade-MMR-evox-thumbnail">
                                            <div className="admin-icon admin-sell-trade-MMR-evox-thumbnail-image">
                                                {
                                                    this.state.isImageAvailable["thumbnailFront"]
                                                        ? <img id="thumbnailFront" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "front" + this.props.imgUrlSeparator + this.state.selectedVehicleOEMColors.code + this.props.imageExtension} alt="Front" role="button" onClick={(event) => this.onThumbnailClick("Front")} onError={(event) => this.onMissingImage(event, false)} />
                                                        : this.state.isDefaultImageAvailable["thumbnailFront"]
                                                            ? <img id="thumbnailFront" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "front" + this.props.imgUrlSeparator + "default" + this.props.imageExtension} alt="Front" role="button" onClick={(event) => this.onThumbnailClick("Front")} onError={(event) => this.onMissingImage(event, true)} />
                                                            : <div className="admin-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                }
                                            </div>
                                            Front
                                        </div>
                                        <div className="admin-icon admin-sell-trade-MMR-evox-thumbnail">
                                            <div className="admin-sell-trade-MMR-evox-thumbnail-image">
                                                {
                                                    this.state.isImageAvailable["thumbnailSide"]
                                                        ? <img id="thumbnailSide" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "side" + this.props.imgUrlSeparator + this.state.selectedVehicleOEMColors.code + this.props.imageExtension} alt="Side" role="button" onClick={(event) => this.onThumbnailClick("Side")} onError={(event) => this.onMissingImage(event, false)} />
                                                        : this.state.isDefaultImageAvailable["thumbnailSide"]
                                                            ? <img id="thumbnailSide" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "side" + this.props.imgUrlSeparator + "default" + this.props.imageExtension} alt="Side" role="button" onClick={(event) => this.onThumbnailClick("Side")} onError={(event) => this.onMissingImage(event, true)} />
                                                            : <div className="admin-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                }
                                            </div>
                                            Side
                                        </div>
                                        <div className="admin-icon admin-sell-trade-MMR-evox-thumbnail">
                                            <div className="admin-sell-trade-MMR-evox-thumbnail-image">
                                                {
                                                    this.state.isImageAvailable["thumbnailRear"]
                                                        ? <img id="thumbnailRear" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "rear" + this.props.imgUrlSeparator + this.state.selectedVehicleOEMColors.code + this.props.imageExtension} alt="Rear" role="button" onClick={(event) => this.onThumbnailClick("Rear")} onError={(event) => this.onMissingImage(event, false)} />
                                                        : this.state.isDefaultImageAvailable["thumbnailRear"]
                                                            ? <img id="thumbnailRear" height="100%" width="100%" src={this.props.imageBaseUrl + vehicleId + this.props.imgUrlSeparator + "rear" + this.props.imgUrlSeparator + "default" + this.props.imageExtension} alt="Rear" role="button" onClick={(event) => this.onThumbnailClick("Rear")} onError={(event) => this.onMissingImage(event, true)} />
                                                            : <div className="admin-sell-trade-thumbnail-water-mark">No Image Available.</div>
                                                }
                                            </div>
                                            Rear
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.isDebug
                                        ? <div className="admin-evox-images-debug">
                                            <div className="admin-evox-images-oem-colors-debug">
                                                {this.state.selectedVehicleOEMColors.code}, #{this.state.selectedVehicleOEMColors.rgb1}, {this.state.selectedVehicleOEMColors.rgb2 ? "#" + this.state.selectedVehicleOEMColors.rgb2 : "null"}
                                                <br />
                                                <button type="button" className="admin-evox-images-oem-colors-raw-view-btn btn btn-link" onClick={(event) => this.onRawViewClick(event)}>
                                                    download/EVOX/Color/{this.props.photoRowData[this.props.vehicleIdPropertyName]}.oemcolors.json
                                                </button>
                                            </div>
                                            <div className="admin-evox-images-thumbnail-debug" style={{ "marginLeft": "12px" }}>
                                                <div className="admin-evox-images-thumbnail-debug-body">
                                                    {this.props.imageContainer}/{this.props.imageBlobPrefix}/{vehicleId}.front.{this.state.selectedVehicleOEMColors.code}.png
                                                </div>
                                            </div>
                                            <div className="admin-evox-images-thumbnail-debug" style={{ "marginLeft": "15px" }}>
                                                <div className="admin-evox-images-thumbnail-debug-body">
                                                    {this.props.imageContainer}/{this.props.imageBlobPrefix}/{vehicleId}.side.{this.state.selectedVehicleOEMColors.code}.png
                                                </div>
                                            </div>
                                            <div className="admin-evox-images-thumbnail-debug" style={{ "marginLeft": "15px" }}>
                                                <div className="admin-evox-images-thumbnail-debug-body">
                                                    {this.props.imageContainer}/{this.props.imageBlobPrefix}/{vehicleId}.rear.{this.state.selectedVehicleOEMColors.code}.png
                                                </div>
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            : this.props.vehicleOEMColors
                                ? <div className="admin-sell-trade-photo-water-mark">No Image Available.</div>
                                : null
                    }
                </div>
            </SellToWebAdminModalDialogComponent>
        </Fragment>;
    }
}
