import { Component } from "react";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";

export class TenantInvoiceRouteComponent extends Component {
    constructor(props) {
        super(props);
        props.onPageChanged({
            pageTitle: this.pageTitle
        });
    }

    pageTitle = "Invoice";

    render() {
        return <SellToWebAdminPageComponent>
            Coming Soon ...
        </SellToWebAdminPageComponent>;
    }
}
