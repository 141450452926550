import React from "react";
import { Link } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { CommonService } from "../js_modules/CommonService";

import { SellToWebAdminPageComponent } from "../common/selltowebpage/SellToWebAdminPageComponent";

import { EVOXImagesMMREVOXMappingComponent } from "./EVOXImagesMMREVOXMappingComponent";
import { EVOXImagesEVOXVehiclesComponent } from "./EVOXImagesEVOXVehiclesComponent";

export class EVOXImagesRouteComponent extends React.Component {
    constructor(props) {
        super(props);
        props.onPageChanged({
            pageTitle: this.pageTitle,
            override: true,
            link: {
                name: this.pageTitle,
                action: "add"
            }
        });

        //console.devLog("Props: " + CommonService.getComponentConstructorData(this.props));
    }

    componentDidMount() {
        try {
            CommonService.clientAzureStorageLog("EVOXImagesRouteComponent", "componentDidMount", null, null, null, null, this.props.applicationInsights);
        }
        catch (error) {
            console.logError(error, "EVOXImagesRouteComponent", "componentDidMount");
        }
    }

    pageTitle = "EVOX Images";

    render() {
        return <SellToWebAdminPageComponent>
            <nav className="nav nav-pills">
                <Link to="/Global/EVOXImages/MMREVOXMapping" className={"nav-link" + (((window.location.pathname.toLocaleLowerCase().includes("mmrevoxmapping") || window.location.pathname.toLocaleLowerCase().includes("evoximages")) && !window.location.pathname.toLocaleLowerCase().includes("evoxvehicles")) ? " active" : "")}>MMR EVOX Mapping</Link>
                <Link to="/Global/EVOXImages/EVOXVehicles" className={"nav-link" + (window.location.pathname.toLocaleLowerCase().includes("evoxvehicles") ? " active" : "")}>EVOX Vehicles</Link>
            </nav>
            <br/>
            <div className="admin-flex-item">
                <Routes>
                    <Route index element={<EVOXImagesMMREVOXMappingComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} />} />
                    <Route exact path="MMREVOXMapping" element={<EVOXImagesMMREVOXMappingComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} />} />
                    <Route exact path="MMREVOXMapping/:yearId/:make/:mmrVehicleId" element={<EVOXImagesMMREVOXMappingComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} />} />
                    <Route exact path="EVOXVehicles" element={<EVOXImagesEVOXVehiclesComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} />} />
                    <Route exact path="EVOXVehicles/:vifNumber" element={<EVOXImagesEVOXVehiclesComponent onShowAlert={this.props.onShowAlert} onPageChanged={this.props.onPageChanged} isGlobalAdmin={this.props.isGlobalAdmin} applicationInsights={this.props.applicationInsights} />} />
                </Routes>
            </div>
        </SellToWebAdminPageComponent>;
    }
}